export const ageGroupData = [
    { id: 1, name: 'Деца (0-17)', from: 0, to: 17  },
    { id: 2, name: 'Млади (18-25)', from: 18, to: 25 },
    { id: 3, name: 'Одрасли (26-64)', from: 26, to: 64 },
    { id: 4, name: 'Старији (65 и више)', from: 65, to: 150 },
    { id: 5, name: 'Непознато', from: 0, to: 0 },
    { id: 6, name: 'Слободан унос опсега година', from: 0, to: 0 },
    { id: 7, name: 'Деца (0-2)', from: 0, to: 2 },
    { id: 8, name: 'Деца (3-5)', from: 3, to: 5 },
    { id: 9, name: 'Деца (6-14)', from: 6, to: 14 },
    { id: 10, name: 'Деца (15-17)', from: 15, to: 17 },
 ];

 export const durationInFacility = [
    { id: 1, name: '0 - 6 месеци', from: 0, to: 6 },
    { id: 2, name: '7 - 12 месеци', from: 7, to: 12 },
    { id: 3, name: '13 - 24 месеци', from: 13, to: 24 },
    { id: 4, name: 'Преко 24 месецa', from: 24, to: 36 },
 ];