/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { CardHeaderComponent } from './components/readonly-card-components/card-header/card-header.component';
import { DefinitionListComponent } from './components/definition-list/definition-list.component';
import { DefinitionItemComponent } from './components/definition-list/definition-item/definition-item.component';
import { CardSubgroupDividerComponent } from './components/readonly-card-components/card-subgroup-divider/card-subgroup-divider.component';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { ArchiveSubjectComponent } from './subjects/subject-details/archive-subject/archive-subject.component';
import { DefinitionItemDividerComponent } from './components/definition-list/definition-item-divider/definition-item-divider.component';
import { SubjectFormComponent } from './subjects/subject-form/subject-form.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppComponent } from './app.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SubjectsComponent } from './subjects/subjects.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DeleteDialogComponent } from './subjects/delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from './../environments/environment';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { LoginComponent } from './login/login.component';
import { TokenInterceptor } from './token.interceptor';
import { AssignCaseDialogComponent } from './assign-case-dialog/assign-case-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ToastrModule } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EntranceComponent } from './entrance/entrance.component';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PageIntroHeaderComponent } from './components/page-intro-header/page-intro-header.component';
import { SortByPipe } from 'src/pipes/sort-by.pipe';
import { ProfileComponent } from './profile/profile.component';
import { CasesComponent } from './cases/cases.component';
import { PageComponent } from './templates/page/page.component';
import { IconFileCSVComponent } from './components/icons/file-csv/icon.component';
import { IconFilePDFComponent } from './components/icons/file-pdf/icon.component';
import { SubjectDetailsComponent } from './subjects/subject-details/subject-details.component';
import { ContainerComponent } from './components/container/container.component';
import { FieldHolderComponent } from './components/form-components/field-holder/field-holder.component';
import { FormGroupComponent } from './components/form-components/form-group/form-group.component';
import { FormFooterComponent } from './components/form-components/form-footer/form-footer.component';
import { FormSubgroupDividerComponent } from './components/form-components/form-subgroup-divider/form-subgroup-divider.component';
import { FamilyMembersComponent } from './entrance/family-members/family-members.component';
import { NewSubjectComponent } from './subjects/new-subject/new-subject.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { SubjectHistoryComponent } from './subjects/subject-details/subject-history/subject-history.component';
import { SubjectCaseComponent } from './subjects/subject-details/subject-case/subject-case.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SubjectCasesComponent } from './subjects/subject-details/subject-cases/subject-cases.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CaseListItemComponent } from './components/case-list-item/case-list-item.component';
import { SubjectProblemsComponent } from './subjects/subject-details/subject-problems/subject-problems.component';
import { SubjectRecordComponent } from './subjects/subject-details/subject-record/subject-record.component';
import { SubjectFamilyComponent } from './subjects/subject-details/subject-family/subject-family.component';
import { FamilyMemberCardComponent } from './components/family-member-card/family-member-card.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NotificationContentComponent } from './notification-content/notification-content.component';
import { DossierStatusLabelComponent } from './components/dossier-status-label/dossier-status-label.component';
import { DatePipe } from '@angular/common';
import { HomepageComponent } from './homepage/homepage.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { SidebarWidgetComponent } from './sidebar-widget/sidebar-widget.component';
import { SubjectDocumentsComponent } from './sidebar-widget/subject-documents/subject-documents.component';
import { IconFileComponent } from './components/icons/icon-file/icon-file.component';
import { IconFileTrackingComponent } from './components/icons/icon-file-tracking/icon-file-tracking.component';
import { DocumentListItemComponent } from './components/document-list-item/document-list-item.component';
import { PreviewDocumentComponent } from './sidebar-widget/preview-document/preview-document.component';
import { TrackingListItemComponent } from './components/tracking-list-item/tracking-list-item.component';
import { SubjectTrackingComponent } from './sidebar-widget/subject-tracking/subject-tracking.component';
import { ManageTrackingComponent } from './sidebar-widget/manage-tracking/manage-tracking.component';
import { DeleteTrackingComponent } from './sidebar-widget/delete-tracking/delete-tracking.component';
import { CollapsibleGroupComponent } from './components/collapsible-group/collapsible-group.component';
import { NspComponent } from './cases/nsp/nsp.component';
import { NspObservationStepComponent } from './cases/nsp/nsp-observation-step/nsp-observation-step.component';
import { NspFamilyStepComponent } from './cases/nsp/nsp-family-step/nsp-family-step.component';
import { NspPropertyStepComponent } from './cases/nsp/nsp-property-step/nsp-property-step.component';
import { NspConclusionStepComponent } from './cases/nsp/nsp-conclusion-step/nsp-conclusion-step.component';
import { VsComponent } from './cases/vs/vs.component';
import { CasePageHeaderComponent } from './components/case-page-header/case-page-header.component';
import { SubjectNewProblemComponent } from './subjects/subject-details/subject-new-problem/subject-new-problem.component';
import { SubjectNewRecordComponent } from './subjects/subject-details/subject-new-record/subject-new-record.component';
import { CloseCaseComponent } from './cases/vs/close-case/close-case.component';
import { NspRequestComponent } from './nsp-request/nsp-request.component';
import { SideSubjectsComponent } from './sidebar-widget/side-subjects/side-subjects.component';
import { OtherInstitutionsComponent } from './sidebar-widget/other-institutions/other-institutions.component';
import { SendingDocumentsComponent } from 'src/app/components/sending-documents/sending-documents.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TextMaskModule } from 'angular2-text-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Form5Component } from './cases/vs/form5/form5.component';
import { TasksComponent } from './cases/vs/form5/tasks/tasks.component';
import { AddUsersComponent } from './cases/vs/form5/add-users/add-users.component';
import { AddUserCommentComponent } from './cases/vs/form5/add-user-comment/add-user-comment.component';
import { InitialAssessmentPlanComponent } from './cases/vs/initial-assessment-plan/initial-assessment-plan.component';
import { MeasuresAndServicesComponent } from './cases/vs/measures-and-services/measures-and-services.component';
import { InitialAssessmentComponent } from './cases/vs/initial-assessment/initial-assessment.component';
import { TargetedAssessmentComponent } from './cases/vs/targeted-assessment/targeted-assessment.component';
import { EvaluationAndReassessmentComponent } from './cases/vs/evaluation-and-reassessment/evaluation-and-reassessment.component';
import { Forms2_3Component } from './cases/vs/initial-assessment/forms2_3/forms2_3.component';
import { TreeChecklistComponent } from './components/tree-checklist/tree-checklist.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DpnComponent } from './cases/dpn/dpn.component';
import { LegalSubjectsComponent } from './legal-subjects/legal-subjects.component';
import { NewLegalSubjectComponent } from './legal-subjects/new-legal-subject/new-legal-subject.component';
import { LegalSubjectFormComponent } from './legal-subjects/legal-subject-form/legal-subject-form.component';
import { LegalSubjectDetailsComponent } from './legal-subjects/legal-subject-details/legal-subject-details.component';
import { LegalSubjectHistoryComponent } from './legal-subjects/legal-subject-details/legal-subject-history/legal-subject-history.component';
import { PrintComponent } from './components/print/print.component';
import { ScheduleCasesComponent } from './schedule-cases/schedule-cases.component';
import { CollapsibleWorkerComponent } from './schedule-cases/collapsible-worker/collapsible-worker.component';
import { CollapsibleCaseComponent } from './schedule-cases/collapsible-case/collapsible-case.component';
import { AdministrativeProcedureComponent } from './cases/administrative-procedure/administrative-procedure.component';
import { SupervisionComponent } from './supervision/supervision.component';
import { CaseItemTitleExpansionPanelComponent } from './supervision/case-item-title-expansion-panel/case-item-title-expansion-panel.component';
import { DocumentItemSupervisionComponent } from './supervision/document-item-supervision/document-item-supervision.component';
import { NoResultItemComponent } from './components/no-result-item/no-result-item.component';
import { DocumentItemEvaluationComponent } from './supervision/document-item-evaluation/document-item-evaluation.component';
import { LabelStepStatusComponent } from './cases/vs/components/label-step-status/label-step-status.component';
import { CollectionsComponent } from './collections/collections.component';
import { FosterChildComponent } from './collections/foster-child/foster-child.component';
import { NewFosterChildRecordComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-record.component';
import { NewFosterChildPersonalInformationStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-personal-information-step/new-foster-child-personal-information-step.component';
import { NewFosterChildParentInformationStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-parent-information-step/new-foster-child-parent-information-step.component';
import { NewFosterChildReasonsForParentalAbsenceStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-reasons-for-parental-absence-step/new-foster-child-reasons-for-parental-absence-step.component';
import { NewFosterChildCloseMembersStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-close-members-step/new-foster-child-close-members-step.component';
import { NewFosterChildCostCarriersStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-cost-carriers-step/new-foster-child-cost-carriers-step.component';
import { NewFosterChildSuitabilityStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-suitability-step/new-foster-child-suitability-step.component';
import { NewFosterChildCenterStepComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-center-step/new-foster-child-center-step.component';
import { FosterParentComponent } from './collections/foster-parent/foster-parent.component';
import { NewFosterParentComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent.component';
import { NewFosterParentPersonalInformationStepComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent-personal-information-step/new-foster-parent-personal-information-step.component';
import { NewFosterParentFamilyInformationStepComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent-family-information-step/new-foster-parent-family-information-step.component';
import { NewFosterParentSuitabilityStepComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent-suitability-step/new-foster-parent-suitability-step.component';
import { NewFosterParentCenterStepComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent-center-step/new-foster-parent-center-step.component';
import { EstablishedFosterCareComponent } from './collections/established-foster-care/established-foster-care.component';
import { NewEstablishedFosterCareComponent } from './collections/established-foster-care/new-established-foster-care/new-established-foster-care.component';
import { AddFosterChildComponent } from './collections/established-foster-care/new-established-foster-care/add-foster-child/add-foster-child.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { SubmissionItemComponent } from './submissions/submission-item/submission-item.component';
import { CaseConclusionComponent } from './components/case-conclusion/case-conclusion.component';
import { TeamRequestComponent } from './team-request/team-request.component';
import { DomesticViolenceVictimComponent } from './collections/domestic-violence-victim/domestic-violence-victim.component';
import { NewDomesticViolenceVictimComponent } from './collections/domestic-violence-victim/new-domestic-violence-victim/new-domestic-violence-victim.component';
import { DomesticViolenceCommitterComponent } from './collections/domestic-violence-committer/domestic-violence-committer.component';
import { NewDomesticViolenceCommitterComponent } from './collections/domestic-violence-committer/new-domestic-violence-committer/new-domestic-violence-committer.component';
import { PnnComponent } from './cases/pnn/pnn.component';
import { OzrComponent } from './cases/ozr/ozr.component';
import { FamilyDataComponent } from './collections/foster-child/new-foster-child-record/family-data/family-data.component';
import { AssuranceComponent } from './cases/assurance/assurance.component';
import { FamilyDossierComponent } from './subjects/subject-details/subject-family/family-dossier/family-dossier.component';
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { NspFamilyRequestComponent } from './nsp-request/nsp-family-request/nsp-family-request.component';
import { PmnComponent } from './cases/pmn/pmn.component';
import { AdoptedChildrenComponent } from './collections/adopted-children/adopted-children.component';
import { NewAdoptedChildComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child.component';
import { NewAdoptedChildAdopteeStepComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child-adoptee-step/new-adopted-child-adoptee-step.component';
import { NewAdoptedChildBiologicalParentsStepComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child-biological-parents-step/new-adopted-child-biological-parents-step.component';
import { NewAdoptedChildAdoptersStepComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child-adopters-step/new-adopted-child-adopters-step.component';
import { NewAdoptedChildOtherInformationStepComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child-other-information-step/new-adopted-child-other-information-step.component';
import { DependentsComponent } from './collections/dependents/dependents.component';
import { NewDependentComponent } from './collections/dependents/new-dependent/new-dependent.component';
import { NewDependentCourtJudgement } from './collections/dependents/new-dependent/new-dependent-court-judgement/new-dependent-court-judgement.component';
import { NewDependentCreditorDebtor } from './collections/dependents/new-dependent/new-dependent-creditor-debtor/new-dependent-creditor-debtor.component';
import { NewDependentDeterminedSupportComponent } from './collections/dependents/new-dependent/new-dependent-determined-support/new-dependent-determined-support.component';
import { NewDependentTerminationSupport } from './collections/dependents/new-dependent/new-dependent-termination-support/new-dependent-termination-support.component';
import { ProtegesComponent } from './collections/proteges/proteges.component';
import { NewProtegeSubjectComponent } from './collections/proteges/new-protege-subject/new-protege-subject.component';
import { NewProtegeComponent } from './collections/proteges/new-protege/new-protege.component';
import { NewProtegePersonalInformationStepComponent } from './collections/proteges/new-protege/new-protege-personal-information-step/new-protege-personal-information-step.component';
import { NewProtegePropertyStepComponent } from './collections/proteges/new-protege/new-protege-property-step/new-protege-property-step.component';
import { NewProtegeGuardianStepComponent } from './collections/proteges/new-protege/new-protege-guardian-step/new-protege-guardian-step.component';
import { NewProtegeTerminationOfGuardianshipStepComponent } from './collections/proteges/new-protege/new-protege-termination-of-guardianship-step/new-protege-termination-of-guardianship-step.component';
import { AccommodationComponent } from './cases/vs/accommodation/accommodation.component';
import { FormsSOComponent } from './cases/vs/accommodation/forms-s-o/forms-s-o.component';
import { SendIntoPlannerComponent } from './send-into-planner/send-into-planner.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { OfficeStatsComponent } from './statistics/office-stats/office-stats.component';
import { BasicStatsComponent } from './statistics/basic-stats/basic-stats.component';
import { DocumentStatsComponent } from './statistics/document-stats/document-stats.component';
import { NspStatsComponent } from './statistics/nsp-stats/nsp-stats.component';
import { RecordControlStatsComponent } from './statistics/record-control-stats/record-control-stats.component';
import { GeneralRecordStatsComponent } from './statistics/general-record-stats/general-record-stats.component';
import { PensionLiabilitiesComponent } from './cases/vs/accommodation/forms-s-o/pension-liabilities/pension-liabilities.component';
import { LiabilitiesPerOtherRevenueComponent } from './cases/vs/accommodation/forms-s-o/liabilities-per-other-revenue/liabilities-per-other-revenue.component';
import { ProtegeDetailComponent } from './collections/proteges/protege-detail/protege-detail.component';
import { EditProtegeDetailComponent } from './collections/proteges/protege-detail/edit-protege-detail/edit-protege-detail.component';
import { AccountingProceduresComponent } from './accounting-procedures/accounting-procedures.component';
import { AssigneDossierComponent } from './subjects/assigne-dossier/assigne-dossier.component';
import { GlobalAdminComponent } from './global-admin/global-admin.component';
import { ManageCipherComponent } from './global-admin/manage-cipher/manage-cipher.component';
import { TargetedAssessmentPlanComponent } from './cases/vs/targeted-assessment-plan/targeted-assessment-plan.component';
import { CollapsibleWorkersGroupsComponent } from './schedule-cases/collapsible-workers-groups/collapsible-workers-groups.component';
import { TimeoutIndicatorComponent } from './components/timeout-indicator/timeout-indicator.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { SubjectDocumentsDialogComponent } from './sidebar-widget/subject-documents/subject-documents-dialog/subject-documents-dialog.component';
import { DomesticViolenceCommiterDetailsComponent } from './collections/domestic-violence-committer/domestic-violence-commiter-details/domestic-violence-commiter-details.component';
import { DomesticViolenceVictimDetailsComponent } from './collections/domestic-violence-victim/domestic-violence-victim-details/domestic-violence-victim-details.component';
import { DependentsDetailsComponent } from './collections/dependents/dependents-details/dependents-details.component';
import { EstablishedFosterCareDetailsComponent } from './collections/established-foster-care/established-foster-care-details/established-foster-care-details.component';
import { AdoptedChildrenDetailsComponent } from './collections/adopted-children/adopted-children-details/adopted-children-details.component';
import { FosterParentDetailsComponent } from './collections/foster-parent/foster-parent-details/foster-parent-details.component';
import { FosterChildDetailsComponent } from './collections/foster-child/foster-child-details/foster-child-details.component';
import { CostsParticipationComponent } from './cases/vs/accommodation/forms-s-o/costs-participation/costs-participation.component';
import { AppDateAdapter } from './utils/AppDateAdapter';
import { LocalComponent } from './cases/local/local.component';
import { ViolenceMembersComponent } from './entrance/violence-members/violence-members.component';
import { ViolenceComponent } from './cases/vs/violence/violence.component';
import { TeamMemberComponent } from './cases/team-member/team-member.component';
import { ImportSubjectFromProviderComponent } from './subjects/import-subject-from-provider/import-subject-from-provider.component';
import { UsersComponent } from './global-admin/users/users.component';
import { NewUserComponent } from './global-admin/users/new-user/new-user.component';
import { UserFormComponent } from './global-admin/users/user-form/user-form.component';
import { UserDetailsComponent } from './global-admin/users/user-details/user-details.component';
import { ImportDataComponent } from './global-admin/import-data/import-data.component';
import { ImportCollectionsComponent } from './global-admin/import-collections/import-collections.component';
import { FileDropDirective } from 'src/directives/file-drop.directive';
import { FileDirective } from 'src/directives/file.directive';
import { ResetPasswordComponent } from './global-admin/users/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/profile-card/change-password/change-password.component';
import { AccommodationLawyerComponent } from './cases/vs/accommodation-lawyer/accommodation-lawyer.component';
import { ForwardCaseDialogComponent } from './forward-case-dialog/forward-case-dialog.component';
import { ReturnCaseDialogComponent } from './return-case-dialog/return-case-dialog.component';
import { EvaluationOfPlanItemComponent } from './components/evaluation-of-plan-item/evaluation-of-plan-item.component';
import { ActivityStatsComponent } from './statistics/activity-stats/activity-stats.component';
import { WorkloadCalculationDialogComponent } from './workload-calculation-dialog/workload-calculation-dialog.component';
import { TerminationOfFosterCareComponent } from './collections/established-foster-care/established-foster-care-details/termination-of-foster-care/termination-of-foster-care.component';
import { EditTerminationOfGuardianshipComponent } from './collections/proteges/protege-detail/edit-termination-of-guardianship/edit-termination-of-guardianship.component';
import { AnnulmentOfAdoptionComponent } from './collections/adopted-children/annulment-of-adoption/annulment-of-adoption.component';
import { ImportDecisionsComponent } from './global-admin/import-decisions/import-decisions.component';
import { BrotherSisterFormComponent } from './cases/vs/accommodation-lawyer/brother-sister-form/brother-sister-form.component';
import { ComplainDecisionComponent } from './components/complain-decision/complain-decision.component';
import { AddJmbgComponent } from './components/profile-card/add-jmbg/add-jmbg.component';
import { FacilityPricingComponent } from './global-admin/facility-pricing/facility-pricing.component';
import { AddCategoryPriceComponent } from './global-admin/facility-pricing/add-category-price/add-category-price.component';
import { JnpStatsComponent } from './statistics/jnp-stats/jnp-stats.component';
import { InputSubjectInSkFormComponent } from './components/input-subject-in-sk-form/input-subject-in-sk-form.component';
import { InputParticipationSkComponent } from './components/input-participation-sk/input-participation-sk.component';
import { WarningSessionComponent } from './components/warning-session/warning-session.component';
import { AdminCasesComponent } from './global-admin/admin-cases/admin-cases.component';
import { ChangeRegIdComponent } from './global-admin/change-reg-id/change-reg-id.component';
import { ChangeRegIdDialogComponent } from './global-admin/change-reg-id/change-reg-id-dialog/change-reg-id-dialog.component';
import { NspAdminComponent } from './global-admin/nsp-admin/nsp-admin.component';
import { DpnAdminComponent } from './global-admin/dpn-admin/dpn-admin.component';
import { VsLawyerComponent } from './cases/vs/vs-lawyer/vs-lawyer.component';
import { CaseSearchByClassificationComponent } from './global-admin/case-search-by-classification/case-search-by-classification.component';
import { DeactivateGuardService } from 'src/services/deactivate-guard.service';
import { AddSupportPriceComponent } from './global-admin/facility-pricing/add-support-price/add-support-price.component';
import { DecisionStatsComponent } from './statistics/decision-stats/decision-stats.component';
import { PocketMoneyPercentageComponent } from './global-admin/pocket-money-percentage/pocket-money-percentage.component';
import { PocketMoneyAmountComponent } from './global-admin/pocket-money-amount/pocket-money-amount.component';
import { TerminationReasonStatsComponent } from './statistics/termination-reason-stats/termination-reason-stats.component';
import { SideSubjectCasesComponent } from './sidebar-widget/side-subject-cases/side-subject-cases.component';
import { TrackingListStatsComponent } from './statistics/tracking-list-stats/tracking-list-stats.component';
import { ViolenceStatsComponent } from './statistics/violence-stats/violence-stats.component';
import { CollectionStatsComponent } from './statistics/collection-stats/collection-stats.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.translateUrl);
}

@NgModule({
  exports: [],
  declarations: [
    AppComponent,
    SubjectsComponent,
    CustomPaginationComponent,
    DeleteDialogComponent,
    NspComponent,
    LoginComponent,
    AssignCaseDialogComponent,
    EntranceComponent,
    SubjectHistoryComponent,
    SubjectCaseComponent,
    SortByPipe,
    ProfileComponent,
    CasesComponent,
    PageComponent,
    IconFileCSVComponent,
    IconFilePDFComponent,
    SubjectDetailsComponent,
    NewSubjectComponent,
    ContainerComponent,
    FieldHolderComponent,
    FormGroupComponent,
    FormFooterComponent,
    FormSubgroupDividerComponent,
    PageIntroHeaderComponent,
    CardHeaderComponent,
    DefinitionListComponent,
    DefinitionItemComponent,
    CardSubgroupDividerComponent,
    StatusLabelComponent,
    ArchiveSubjectComponent,
    DefinitionItemDividerComponent,
    SubjectFormComponent,
    FamilyMembersComponent,
    SubjectCasesComponent,
    CaseListItemComponent,
    SubjectProblemsComponent,
    SubjectRecordComponent,
    SubjectFamilyComponent,
    FamilyMemberCardComponent,
    CollapsibleGroupComponent,
    NspObservationStepComponent,
    NspFamilyStepComponent,
    NspPropertyStepComponent,
    NspConclusionStepComponent,
    PageNotFoundComponent,
    NotificationContentComponent,
    DossierStatusLabelComponent,
    HomepageComponent,
    SidebarWidgetComponent,
    SubjectDocumentsComponent,
    IconFileComponent,
    IconFileTrackingComponent,
    DocumentListItemComponent,
    WarningMessageComponent,
    PreviewDocumentComponent,
    TrackingListItemComponent,
    SubjectTrackingComponent,
    ManageTrackingComponent,
    DeleteTrackingComponent,
    CollapsibleGroupComponent,
    VsComponent,
    CasePageHeaderComponent,
    InitialAssessmentPlanComponent,
    MeasuresAndServicesComponent,
    SubjectNewProblemComponent,
    SubjectNewRecordComponent,
    InitialAssessmentComponent,
    TargetedAssessmentComponent,
    EvaluationAndReassessmentComponent,
    CloseCaseComponent,
    NspRequestComponent,
    NspFamilyRequestComponent,
    SideSubjectsComponent,
    OtherInstitutionsComponent,
    SendingDocumentsComponent,
    Form5Component,
    TasksComponent,
    AddUsersComponent,
    AddUserCommentComponent,
    Forms2_3Component,
    TreeChecklistComponent,
    DpnComponent,
    LegalSubjectsComponent,
    NewLegalSubjectComponent,
    LegalSubjectFormComponent,
    LegalSubjectDetailsComponent,
    LegalSubjectHistoryComponent,
    PrintComponent,
    ScheduleCasesComponent,
    CollapsibleWorkerComponent,
    CollapsibleCaseComponent,
    AdministrativeProcedureComponent,
    SupervisionComponent,
    CaseItemTitleExpansionPanelComponent,
    DocumentItemSupervisionComponent,
    NoResultItemComponent,
    DocumentItemEvaluationComponent,
    LabelStepStatusComponent,
    CollectionsComponent,
    FosterChildComponent,
    NewFosterChildRecordComponent,
    NewFosterChildPersonalInformationStepComponent,
    NewFosterChildParentInformationStepComponent,
    NewFosterChildReasonsForParentalAbsenceStepComponent,
    NewFosterChildCloseMembersStepComponent,
    NewFosterChildCostCarriersStepComponent,
    NewFosterChildSuitabilityStepComponent,
    NewFosterChildCenterStepComponent,
    FosterParentComponent,
    NewFosterParentComponent,
    NewFosterParentPersonalInformationStepComponent,
    NewFosterParentFamilyInformationStepComponent,
    NewFosterParentSuitabilityStepComponent,
    NewFosterParentCenterStepComponent,
    EstablishedFosterCareComponent,
    NewEstablishedFosterCareComponent,
    AddFosterChildComponent,
    SubmissionsComponent,
    SubmissionItemComponent,
    CaseConclusionComponent,
    TeamRequestComponent,
    DomesticViolenceVictimComponent,
    NewDomesticViolenceVictimComponent,
    DomesticViolenceCommitterComponent,
    NewDomesticViolenceCommitterComponent,
    PnnComponent,
    OzrComponent,
    FamilyDataComponent,
    AssuranceComponent,
    FamilyDossierComponent,
    PmnComponent,
    AdoptedChildrenComponent,
    NewAdoptedChildComponent,
    NewAdoptedChildAdopteeStepComponent,
    NewAdoptedChildBiologicalParentsStepComponent,
    NewAdoptedChildAdoptersStepComponent,
    NewAdoptedChildOtherInformationStepComponent,
    DependentsComponent,
    NewDependentComponent,
    NewDependentCourtJudgement,
    NewDependentCreditorDebtor,
    NewDependentDeterminedSupportComponent,
    NewDependentTerminationSupport,
    ProtegesComponent,
    NewProtegeSubjectComponent,
    NewProtegeComponent,
    NewProtegePersonalInformationStepComponent,
    NewProtegePropertyStepComponent,
    NewProtegeGuardianStepComponent,
    NewProtegeTerminationOfGuardianshipStepComponent,
    AccommodationComponent,
    FormsSOComponent,
    SendIntoPlannerComponent,
    StatisticsComponent,
    OfficeStatsComponent,
    BasicStatsComponent,
    DocumentStatsComponent,
    NspStatsComponent,
    RecordControlStatsComponent,
    GeneralRecordStatsComponent,
    PensionLiabilitiesComponent,
    LiabilitiesPerOtherRevenueComponent,
    ProtegeDetailComponent,
    EditProtegeDetailComponent,
    AccountingProceduresComponent,
    AssigneDossierComponent,
    GlobalAdminComponent,
    ManageCipherComponent,
    TargetedAssessmentPlanComponent,
    CollapsibleWorkersGroupsComponent,
    TimeoutIndicatorComponent,
    NotificationsDropdownComponent,
    ProfileCardComponent,
    ProfileDropdownComponent,
    OnlyNumberDirective,
    SubjectDocumentsDialogComponent,
    DomesticViolenceCommiterDetailsComponent,
    DomesticViolenceVictimDetailsComponent,
    DependentsDetailsComponent,
    EstablishedFosterCareDetailsComponent,
    AdoptedChildrenDetailsComponent,
    FosterParentDetailsComponent,
    FosterChildDetailsComponent,
    UserFormComponent,
    CostsParticipationComponent,
    LocalComponent,
    ViolenceMembersComponent,
    ViolenceComponent,
    TeamMemberComponent,
    ImportSubjectFromProviderComponent,
    UsersComponent,
    NewUserComponent,
    UserDetailsComponent,
    ImportDataComponent,
    FileDropDirective,
    FileDirective,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ImportCollectionsComponent,
    AccommodationLawyerComponent,
    ForwardCaseDialogComponent,
    ReturnCaseDialogComponent,
    EvaluationOfPlanItemComponent,
    ActivityStatsComponent,
    WorkloadCalculationDialogComponent,
    TerminationOfFosterCareComponent,
    EditTerminationOfGuardianshipComponent,
    AnnulmentOfAdoptionComponent,
    ImportDecisionsComponent,
    BrotherSisterFormComponent,
    ComplainDecisionComponent,
    AddJmbgComponent,
    FacilityPricingComponent,
    AddCategoryPriceComponent,
    JnpStatsComponent,
    InputSubjectInSkFormComponent,
    InputParticipationSkComponent,
    WarningSessionComponent,
    AdminCasesComponent,
    ChangeRegIdComponent,
    ChangeRegIdDialogComponent,
    NspAdminComponent,
    DpnAdminComponent,
    VsLawyerComponent,
    CaseSearchByClassificationComponent,
    AddSupportPriceComponent,
    DecisionStatsComponent,
    PocketMoneyPercentageComponent,
    PocketMoneyAmountComponent,
    TerminationReasonStatsComponent,
    SideSubjectCasesComponent,
    TrackingListStatsComponent,
    ViolenceStatsComponent,
    CollectionStatsComponent,
  ],
  imports: [
    BrowserModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule,
    MatRippleModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCardModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatBadgeModule,
    MatTreeModule,
    StorageServiceModule,
    MatSlideToggleModule,
    NgxDocViewerModule,
    KeyboardShortcutsModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    TextMaskModule,
    AutocompleteLibModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'sr-Cyrl' },
    DeactivateGuardService
  ],
  entryComponents: [
    WorkloadCalculationDialogComponent,
    DeleteDialogComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    AssignCaseDialogComponent,
    ForwardCaseDialogComponent,
    ReturnCaseDialogComponent,
    SubjectHistoryComponent,
    SubjectCaseComponent,
    SubjectDocumentsComponent,
    ArchiveSubjectComponent,
    EntranceComponent,
    FamilyMembersComponent,
    PreviewDocumentComponent,
    ManageTrackingComponent,
    DeleteTrackingComponent,
    SubjectNewProblemComponent,
    SubjectNewRecordComponent,
    CloseCaseComponent,
    NspFamilyRequestComponent,
    SendingDocumentsComponent,
    AddUserCommentComponent,
    TasksComponent,
    AddUsersComponent,
    PrintComponent,
    WarningMessageComponent,
    AddFosterChildComponent,
    TeamRequestComponent,
    FamilyDossierComponent,
    FamilyDataComponent,
    NewDependentCourtJudgement,
    NewDependentCreditorDebtor,
    NewDependentDeterminedSupportComponent,
    NewDependentTerminationSupport,
    NewProtegeSubjectComponent,
    SendIntoPlannerComponent,
    PensionLiabilitiesComponent,
    LiabilitiesPerOtherRevenueComponent,
    ProtegeDetailComponent,
    SendIntoPlannerComponent,
    EditProtegeDetailComponent,
    AssigneDossierComponent,
    ManageCipherComponent,
    SubjectDocumentsDialogComponent,
    UserFormComponent,
    CostsParticipationComponent,
    ViolenceMembersComponent,
    ImportSubjectFromProviderComponent,
    TerminationOfFosterCareComponent,
    EditTerminationOfGuardianshipComponent,
    AnnulmentOfAdoptionComponent,
    BrotherSisterFormComponent,
    ComplainDecisionComponent,
    AddJmbgComponent,
    AddCategoryPriceComponent,
    InputSubjectInSkFormComponent,
    InputParticipationSkComponent,
    ChangeRegIdDialogComponent,
    AddSupportPriceComponent
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

  constructor() {
    //   dateAdapter.setLocale('sr-Cyrl');
  }
}
