/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTable } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject } from 'src/models/subject.model';
import { CodebookService } from 'src/services/codebook.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { EntranceService } from 'src/services/entrance.service';
import { SideNavService } from 'src/services/side-nav.service';
import { SubjectsService } from 'src/services/subjects.service';
import { SubmissionService } from 'src/services/submission.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { LegalSubjectsComponent } from '../legal-subjects/legal-subjects.component';
import { LocalStorageService } from '../local-storage.service';
import { SubjectsComponent } from '../subjects/subjects.component';
import { autoSelect, sortByFirstLetter } from '../utils/autoSelect';
import * as myLetters from './../letters';
import { FamilyMembersComponent } from './family-members/family-members.component';
import { EventEnum, SubmitterData, YesOrNoEnum, YesOrNoStatuses } from './types';
import { ViolenceMembersComponent } from './violence-members/violence-members.component';

enum AutoCompleteFilterEnum {
   CASE_CLASSIFICATION = 'caseClassification',
   OFFICE_CODEBOOK = 'officeCodebook',
}

@Component({
   selector: 'app-entrance',
   templateUrl: './entrance.component.html',
   styleUrls: ['./entrance.component.scss'],
   providers: [DatePipe],
})
export class EntranceComponent implements OnInit {
   firstStep: FormGroup;
   secondStep: FormGroup;
   thirdStep: FormGroup;
   fourthStep: FormGroup;
   violenceStep: FormGroup;
   submitterData: SubmitterData;
   isSigned = false;
   currentDate = new Date();
   center: string;
   showDate: string;
   showTime: string;
   members = [];
   subjectId: any;
   memberRegs = [];
   isLoading = false;
   isMemberSelected = false;
   selectedMember = null;
   signature = null;
   csrId: any;
   caseKindCodebook = [];
   kindOfSubmissionOptions = [];
   enrollmentEvidentions = [];
   entranceDecisions = [];
   caseTypeCodebook = [];
   loggedIn: any;
   violenceCommitters = [];
   isViolenceCommitterSelected = false;
   selectedViolenceCommiter = null;

   caseClassifications = [];
   filteredOptions: Observable<any[]>;

   officeCodebook = [];
   filteredOfficeCodebookOptions: Observable<any[]>;

   submissionId: any;
   submissionName: any;
   submission: any = null;

   isSubmission = false;

   isVisibleAcceptSubmission = null;

   title = 'Пријемни лист';
   titleAddition = null;

   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild(MatTable, { static: true }) table: MatTable<any>;
   @ViewChild('submitButton') submitButton;

   createManualy = false;

   displayedColumns: string[] = ['regNumber', 'name', 'relationship', 'yearOfBirth', 'qualificationEmployment'];

   violenceCommitterColumns: string[] = ['subjectId', 'regNumber', 'name', 'relationship'];

   submissionsOptions = [];

   caseProcessingOptions: YesOrNoStatuses[] = [
      { value: YesOrNoEnum.DA, viewValue: 'Да' },
      { value: YesOrNoEnum.NE, viewValue: 'Не' },
   ];

   isCheckedViolence = false;
   violenceKindCodebook = [];
   availabilityOfVictimToPerpetratorCodebook = [];
   emergencyMeasuresCodebook = [];
   chosenCaseKindCodebookId: '';

   constructor(
      private dataService: DataSharingService,
      public dialog: MatDialog,
      public entranceService: EntranceService,
      private datePipe: DatePipe,
      private formBuilder: FormBuilder,
      private localStorageService: LocalStorageService,
      private router: Router,
      private codebookService: CodebookService,
      private submissionService: SubmissionService,
      private translate: TranslateService,
      private sideNavService: SideNavService,
      private toastr: ToastrImplService,
      private subjectService: SubjectsService
   ) {
      this.title = '';
      this.translate.get('SNACKBAR.T_ENTRANCE_PAPER').subscribe((resp: string) => {
         this.title = resp;
      });
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
      this.router.events.subscribe(ev => {
         if (ev instanceof NavigationEnd) {
            if (ev.url.includes('/cases/')) {
               const [_, submissionType] = ev.url.split('/cases/');
               if (submissionType !== '') {
               }
            } else if (ev.url.includes('/submissions/')) {
               const [_, submissionType] = ev.url.split('/submissions/');
               this.isVisibleAcceptSubmission = true;

               /* Otvaranje prijemnog lista na osnovu podneska */
               if (submissionType !== '') {
                  [this.submissionName, this.submissionId] = submissionType.split('/');
                  submissionService.findById(this.submissionId).subscribe(result => {
                     // Treba uzeti i obrisati sve podatke sa forme
                     this.submission = result.submission;
                     if (this.titleAddition == null) {
                        this.translate.get('SNACKBAR.T_FOR_SUBMISSION').subscribe((resp: string) => {
                           this.titleAddition = resp;
                           this.title += this.titleAddition;
                        });
                     }
                     if (this.submission.status === 'ACCEPTED') {
                        this.dataService.acceptedSubmission.next(true);
                        this.isVisibleAcceptSubmission = false;
                     } else {
                        this.dataService.acceptedSubmission.next(false);
                     }

                     this.firstStep.markAsUntouched();
                     this.secondStep.markAsUntouched();
                     this.thirdStep.markAsUntouched();
                     this.fourthStep.markAsUntouched();
                     if (result.address != null) {
                        this.firstStep.patchValue({
                           addressOfSubmitter: this.formatAddress(result.address),
                        });
                     }
                     this.firstStep.patchValue({
                        enrollmentEvidention: result.submission.enrollmentEvidention,
                        kindOfSubmission: result.submission.submissionTypeCodebook,
                        typeOfSubmission: result.submission.submissionKindCodebook,
                        nameOfSubmitter: result.firstName === null ? null : result.firstName,
                        surnameOfSubmitter: result.lastName === null ? null : result.lastName,
                        phoneOfSubmitter: result.phone === null ? null : result.phone,
                        submissionNumer: result.submission.documentNumber,
                        csrReason: '',
                        otherService: '',
                        reasonForSubmit: '',
                     });

                     if (result.submission.subject != null) {
                        this.secondStep.patchValue({
                           firstName: this.submission.subject.firstName,
                           lastName: this.submission.subject.lastName,
                           gender: this.submission.subject.gender == null ? null : this.submission.subject.gender.gender,
                           dateOfBirth: this.submission.subject.dateOfBirth === null ? null : this.submission.subject.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.'),
                           placeOfBirth: this.submission.subject.placeOfBirth,
                           jmbg: this.submission.subject.jmbg,
                           permanentResidence: this.formatAddress(this.submission.subject.permanentResidence),
                           phone: this.submission.subject.phone,
                           residence: this.formatAddress(this.submission.subject.residence),
                           mobile: this.submission.subject.mobile,
                           nationality: this.submission.subject.nationality == null ? null : this.submission.subject.nationality.title,
                           citizenship: this.submission.subject.citizenship === null ? null : this.submission.subject.citizenship.title,
                           nativeLanguage: this.submission.subject.language === null ? null : this.submission.subject.language.title,
                           education: this.submission.subject.education === null ? null : this.submission.subject.education.title,
                           ableToWork: this.translateYesNo(this.submission.subject.ableToWork),
                           occupation: this.submission.subject.occupation === null ? null : this.submission.subject.occupation.title,
                           employment: this.submission.subject.employment === null ? null : this.submission.subject.employment.title,
                           subjectId: this.submission.subject.subjectIdentity.subjectId,
                           usefulData: '',
                           foreigner: this.submission.subject.foreigner,
                        });

                        this.subjectId = this.submission.subject.subjectIdentity.subjectId;
                        this.clearFields();
                        this.fourthStep.patchValue({
                           subjectId: this.submission.subject.registrationId,
                        });
                     } else {
                        this.secondStep.reset();
                        this.clearFields();
                     }

                     this.firstStep.controls.submissionNumer.setValidators([Validators.required]);
                     this.isSubmission = true;
                  });
               }
               /* Kreiranje prijemnog lista bez podneska.
                    Prijemni list se sacuva u bazu, stampa se i salje u papirnom obliku na pisarnicu bez integracije. */
            } else if (ev.url.includes('/entrancePapers')) {
               this.createManualy = true;
            }
         }
      });

      this.center = JSON.parse(this.localStorageService.get('loggedUser')).center;
      this.csrId = JSON.parse(this.localStorageService.get('loggedUser')).csrId;
      this.showDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
      this.showTime = this.datePipe.transform(this.currentDate, 'HH:mm');

      this.getCodebooks();
   }

   clearFields() {
      this.fourthStep.patchValue({
         subjectId: '',
         entranceDecision: '',
         caseClassification: '',
         decisionAddInfo: '',
         conclusion_explanation: '',
         orgUnitCodebookId: '',
         caseTypeCodebookId: '',
         caseKindCodebookName: '',
         caseKindCodebookId: '',
      });

      this.thirdStep.patchValue({
         note: '',
      });
   }
   ngOnInit(): void {
      this.firstStep = this.formBuilder.group({
         center: [this.center, [Validators.required]],
         entranceIdentity: ['', []],
         submissionNumer: [''],
         dateOfSubmission: [this.showDate, [Validators.required]],
         timeOfSubmission: [this.showTime, [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')]],
         typeOfSubmission: ['', [Validators.required]],
         kindOfSubmission: ['', [Validators.required]],
         CSRReason: [null],
         enrollmentEvidention: ['', [Validators.required]],
         nameOfSubmitter: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
         surnameOfSubmitter: ['', []],
         phoneOfSubmitter: ['', [Validators.minLength(9), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]],
         addressOfSubmitter: ['', []],
         otherService: ['', []],
         reasonForSubmit: ['', []],
      });

      this.setConditionallyCSRReasonValidators();
      this.setConditionallyAnonymousValidators();

      this.secondStep = this.formBuilder.group({
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         gender: ['', []],
         dateOfBirth: ['', []],
         placeOfBirth: ['', []],
         jmbg: ['', []],
         permanentResidence: ['', []],
         phone: ['', []],
         residence: ['', []],
         mobile: ['', []],
         nationality: [''],
         citizenship: [''],
         nativeLanguage: [''],
         education: ['', []],
         ableToWork: ['', []],
         occupation: ['', []],
         employment: ['', []],
         usefulData: ['', []],
         yearOfBirth: [''],
         foreigner: [false],
      });

      this.thirdStep = this.formBuilder.group({
         note: ['', []],
      });

      this.fourthStep = this.formBuilder.group({
         entranceDecision: ['', [Validators.required]],
         caseClassification: ['', []],
         decisionAddInfo: ['', []],
         conclusion_explanation: ['', []],
         subjectId: ['', []],
         familyRegNumb: ['', []],
         orgUnitCodebookId: [''],
         caseTypeCodebookId: [''],
         caseKindCodebookId: [''],
         caseKindCodebookName: [''],
      });

      this.violenceStep = this.formBuilder.group({
         violenceKindCodebookIds: ['', [Validators.required]],
         availabilityVictimToPerpetrator: [''],
         emergencyMeasures: [''],
         violenceDescription: [''],
      });

      this.filteredOptions = this.fourthStep.controls.caseClassification.valueChanges.pipe(
         startWith(''),
         map(value => (typeof value === 'string' ? value : value.title)),
         map(title => (title ? this._filter(title, AutoCompleteFilterEnum.CASE_CLASSIFICATION) : this.caseClassifications.slice()))
      );
      this.setConditionallyDecisionAddInfoValidators();
   }
   translateYesNo(input: boolean | null) {
      let msg = '';
      if (input === true) {
         this.translate.get('GLOBAL.YES').subscribe((res: string) => {
            msg = res;
         });
      } else if (input === false) {
         this.translate.get('GLOBAL.NO').subscribe((res: string) => {
            msg = res;
         });
      }
      return msg;
   }
   setConditionallyDecisionAddInfoValidators() {
      const decisionAddInfo = this.fourthStep.get('decisionAddInfo');
      this.fourthStep.get('entranceDecision').valueChanges.subscribe(item => {
         if (item.id === 6) {
            decisionAddInfo.setValidators([Validators.required]);
         } else {
            decisionAddInfo.setValidators(null);
         }
         decisionAddInfo.updateValueAndValidity();
      });
   }

   setConditionallyCSRReasonValidators() {
      const CSRReason = this.firstStep.get('CSRReason');
      this.firstStep.get('enrollmentEvidention').valueChanges.subscribe(item => {
         if (item !== null) {
            if (item.id === 4) {
               CSRReason.setValidators([Validators.required]);
            } else {
               CSRReason.setValidators(null);
            }
         }
         CSRReason.updateValueAndValidity();
      });
   }

   importSubmitterData(tipKorisnika: 'fizicka_lica' | 'pravna_lica'): void {
      let dialogRef = null;
      if (tipKorisnika === 'fizicka_lica') {
         dialogRef = this.dialog.open(SubjectsComponent, {
            width: '1200px',
            panelClass: 'overlay-panel',
            data: {
               origin: 'entrance',
            },
         });
      } else if (tipKorisnika === 'pravna_lica') {
         dialogRef = this.dialog.open(LegalSubjectsComponent, {
            width: '1200px',
            panelClass: 'overlay-panel',
            data: {
               origin: 'entrance',
            },
         });
      }

      dialogRef.afterClosed().subscribe((result: any) => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            if (tipKorisnika === 'fizicka_lica') {
               const {
                  data: { firstName, lastName, residence, permanentResidence, phone },
               } = obj;
               this.firstStep.patchValue({
                  nameOfSubmitter: firstName,
                  surnameOfSubmitter: lastName,
                  addressOfSubmitter: residence ? this.formatAddress(residence) : this.formatAddress(permanentResidence),
                  phoneOfSubmitter: phone,
               });
            } else if (tipKorisnika === 'pravna_lica') {
               const {
                  data: { institutionType, name, address, phone },
               } = obj;
               this.firstStep.patchValue({
                  nameOfSubmitter: institutionType?.name,
                  surnameOfSubmitter: name,
                  addressOfSubmitter: this.formatAddress(address),
                  phoneOfSubmitter: phone,
               });
            }
         }
      });
   }

   /** Import subject data from register and fill automaticaly appropriate fields */
   importSubjectData(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.members = [];
            const obj = JSON.parse(JSON.stringify(result));
            this.subjectId = result.data.subjectIdentity.subjectId;

            const {
               data: {
                  firstName,
                  lastName,
                  gender,
                  dateOfBirth,
                  placeOfBirth,
                  permanentResidence,
                  jmbg,
                  phone,
                  residence,
                  mobile,
                  nationality,
                  citizenship,
                  language,
                  education,
                  ableToWork,
                  occupation,
                  employment,
                  usefulData,
                  registrationId,
                  yearOfBirth,
                  familyMembersNames,
                  familyDossierId,
                  subjectIdentity: { subjectId, csrId },
                  foreigner,
               },
            } = obj;
            if (familyDossierId !== null) {
               this.subjectService.getFamilyMembersDossierDto(subjectId, familyDossierId, csrId).subscribe(res => {
                  this.members = res;
               });
            }
            this.secondStep.patchValue({
               firstName,
               lastName,
               gender: gender == null ? null : gender.gender,
               dateOfBirth: dateOfBirth === null ? null : dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.'),
               placeOfBirth,
               jmbg,
               permanentResidence: this.formatAddress(permanentResidence),
               phone,
               residence: this.formatAddress(residence),
               mobile,
               nationality: nationality == null ? null : nationality.title,
               citizenship: citizenship === null ? null : citizenship.title,
               nativeLanguage: language === null ? null : language.title,
               education: education === null ? null : education.title,
               ableToWork: this.translateYesNo(ableToWork),
               occupation: occupation === null ? null : occupation.title,
               employment: employment === null ? null : employment.title,
               usefulData,
               subjectId: this.subjectId,
               yearOfBirth,
               foreigner,
            });

            this.fourthStep.patchValue({
               subjectId: registrationId,
            });
         }
      });
   }

   compareYesNoObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.name === object2.name;
   }

   compareGenderObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.gender === object2.gender && object1.code === object2.code;
   }

   /** Select family member and then propagate the data to family member dialog */
   openFamilyMembersDialog(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: { firstName, lastName, registrationId, yearOfBirth, occupation, ableToWork, subjectIdentity, dateOfBirth, jmbg, foreigner },
            } = obj;

            const memberData = {
               firstName,
               lastName,
               registrationId,
               yearOfBirth,
               occupation: occupation !== null ? occupation.title : 'Непознато',
               ableToWork,
               subjectIdentity,
               dateOfBirth,
               jmbg,
               foreigner,
            };
            if (this.checkIfMembersContains(memberData)) {
               let message = '';
               this.translate.get('SNACKBAR.PARTIAL.USER').subscribe((res: string) => {
                  message = res;
               });
               let message2 = '';
               this.translate.get('SNACKBAR.PARTIAL.USER_ADDED').subscribe((res: string) => {
                  message2 = res;
               });
               this.toastr.showError(message + memberData.firstName + ' ' + memberData.lastName + message2);
               return;
            } else {
               this.importFamilyMember(memberData);
            }
         }
      });
   }

   checkIfMembersContains(newMember: any) {
      for (const member of this.members) {
         if (member.subjectIdentity?.subjectId === newMember.subjectIdentity.subjectId) {
            return true;
         }
      }
      return false;
   }

   importFamilyMember(data: Partial<Subject>) {
      const familyData = {
         origin: 'entrance',
         data,
         subject: this.secondStep.value,
      };
      familyData.subject.dateOfBirth = familyData.subject.dateOfBirth?.toString().replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$1/$2/$3');
      const dialogRef = this.dialog.open(FamilyMembersComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: '480px',
         data: familyData,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === EventEnum.SUBMIT) {
            // Ukoliko vec postoje clanovi porodice, potrebno je provjeriti da li je selektovani korisnik prethodno dodat
            this.members = [...this.members, ...data];
            for (const member of data) {
               if (member.regNumber !== null) {
                  this.memberRegs.push(member.regNumber);
               }
            }
            this.fourthStep.patchValue({
               familyRegNumb: this.memberRegs,
            });
         }
      });
   }

   /* Dodavanje clana porodice koji ne postoji u registru.
    U ovom slucaju polje za dodavanje registarskog broja je sakriveno. */
   addFamilyMember() {
      const dialogRef = this.dialog.open(FamilyMembersComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: '480px',
         data: {
            // entranceNotRegistered se koristi umjesto entrance da bi napravili razliku kada treba da prikazujemo regNumber
            origin: 'entranceNotRegistered',
            subject: this.secondStep.value,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === EventEnum.SUBMIT) {
            // Polja ableToWork i disabledPerson ne treba da se popunjavaju prilikom kreiranja objekta za neregistrovane korisnike.
            // Zbog toga su njihove vrijednosti rucno postavljene na null (inace bude '', a ocekuje se objekat)
            for (const member of data) {
               member.ableToWork = null;
               member.disabledPerson = null;
            }
            this.members = [...this.members, ...data];
         }
      });
   }

   /* Dodavanje izvrsioca nasilja koji ne postoji u registru.*/
   addViolenceCommitter() {
      const dialogRef = this.dialog.open(ViolenceMembersComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: '480px',
         data: {
            origin: 'entranceViolenceNotRegistered',
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === EventEnum.SUBMIT) {
            this.violenceCommitters = [...this.violenceCommitters, ...data];
         }
      });
   }

   /** Select violence committer and then propagate the data to modal dialog */
   openViolenceCommitterDialog(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: { firstName, lastName, registrationId, subjectIdentity, dateOfBirth, jmbg, yearOfBirth, foreigner },
            } = obj;

            const committerData = {
               firstName,
               lastName,
               registrationId,
               subjectIdentity,
               dateOfBirth,
               jmbg,
               yearOfBirth,
               foreigner,
            };
            if (this.checkIfViolenceCommittersContains(committerData)) {
               let message = '';
               this.translate.get('SNACKBAR.PARTIAL.USER').subscribe((res: string) => {
                  message = res;
               });
               let message2 = '';
               this.translate.get('SNACKBAR.PARTIAL.USER_ADDED').subscribe((res: string) => {
                  message2 = res;
               });
               this.toastr.showError(message + committerData.firstName + ' ' + committerData.lastName + message2);
               return;
            } else {
               this.importViolenceCommitter(committerData);
            }
         }
      });
   }

   importViolenceCommitter(data: Partial<Subject>) {
      const subject = this.secondStep.value;
      subject.dateOfBirth = subject.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$1/$2/$3');
      const committerData = {
         origin: 'entranceViolence',
         data,
         subject,
      };

      const dialogRef = this.dialog.open(ViolenceMembersComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: '650px',
         data: committerData,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === EventEnum.SUBMIT) {
            // Ukoliko vec postoje izvrsioci nasilja, potrebno je provjeriti da li je selektovani korisnik prethodno dodat
            this.violenceCommitters = [...this.violenceCommitters, ...data];
         }
      });
   }

   checkIfViolenceCommittersContains(newCommitter: any) {
      for (const committer of this.violenceCommitters) {
         if (committer.subjectIdentity?.subjectId === newCommitter.subjectIdentity.subjectId) {
            return true;
         }
      }
      return false;
   }

   handleSignature() {
      const loggedUser = JSON.parse(this.localStorageService.get('loggedUser'));
      this.submitterData = {
         ...this.submitterData,
         signature: loggedUser.firstName + ' ' + loggedUser.lastName,
      };
      this.signature = loggedUser.firstName + ' ' + loggedUser.lastName;
      this.isSigned = true;

      this.saveEntrancePaper();
   }

   saveEntrancePaper() {
      this.checkClasificationCase(true);
      if (!this.fourthStep.valid) {
         return;
      }
      const submitData = this.createEntranceObject();
      this.isLoading = true;
      this.entranceService.addEntrancePaper(submitData).subscribe(
         (result: any) => {
            this.sideNavService.toggle();
            this.router.navigateByUrl('/subjects/' + this.subjectId);
            this.toastr.success('SNACKBAR.RECEIPT_SHEET_SAVED');
         },
         error => {
            this.isLoading = false;
            this.submitButton.disabled = false;
            this.toastr.error('SNACKBAR.ERROR_OCCURRED');
         }
      );
   }

   createEntranceObject() {
      let familyRegNumb = null;
      if (this.memberRegs.length !== 0) {
         familyRegNumb = this.fourthStep.value.familyRegNumb.map(e => {
            return JSON.stringify(e);
         });
         familyRegNumb = familyRegNumb.join(',');
      }

      const entrance = {
         id: this.firstStep.value.entranceIdentity,
         center: this.firstStep.value.center,
         subjectId: this.subjectId,
         dateOfSubmission: this.firstStep.value.dateOfSubmission,
         timeOfSubmission: this.firstStep.value.timeOfSubmission,
         submissionKindCodebook: this.firstStep.value.typeOfSubmission !== '' ? this.firstStep.value.typeOfSubmission : null,
         submissionTypeCodebook: this.kindOfSubmissionOptions.filter(x => x.id === this.firstStep.value.kindOfSubmission.id)[0],
         nameOfSubmitter: this.firstStep.value.nameOfSubmitter,
         surnameOfSubmitter: this.firstStep.value.surnameOfSubmitter,
         addressOfSubmitter: this.firstStep.value.addressOfSubmitter,
         phoneOfSubmitter: this.firstStep.value.phoneOfSubmitter,
         reasonForSubmit: this.firstStep.value.reasonForSubmit,
         enrollmentEvidention: this.enrollmentEvidentions.filter(x => x === this.firstStep.value.enrollmentEvidention)[0],
         csrReason: this.firstStep.value.CSRReason,
         otherService: this.firstStep.value.otherService,
         usefulData: this.secondStep.value.usefulData,
         entranceDecision: this.entranceDecisions.filter(x => x === this.fourthStep.value.entranceDecision)[0],
         caseClassification: this.fourthStep.value.caseClassification !== '' ? this.fourthStep.value.caseClassification : null,
         decisionAddInfo: this.fourthStep.value.decisionAddInfo,
         conclusionExplanation: this.fourthStep.value.conclusion_explanation,
         familyMembers: this.members,
         familyRegNumb,
         note: this.thirdStep.value.note,
         violenceCommitters: this.violenceCommitters,
         violenceKindCodebookIds: this.violenceStep.value.violenceKindCodebookIds !== '' ? this.violenceStep.value.violenceKindCodebookIds : null,
         emergencyMeasures: this.violenceStep.value.emergencyMeasures !== '' ? this.violenceStep.value.emergencyMeasures : null,
         availabilityVictimToPerpetrator: this.violenceStep.value.availabilityVictimToPerpetrator !== '' ? this.violenceStep.value.availabilityVictimToPerpetrator : null,
         violenceDescription: this.violenceStep.value.violenceDescription,
         signature: this.signature,
         orgUnitCodebookId: this.fourthStep.value.orgUnitCodebookId !== '' ? this.fourthStep.value.orgUnitCodebookId : null,
         caseTypeCodebookId: 3,
         submissionId: this.submission !== null ? this.submission.id : null,
         documentId: this.submission !== null ? this.submission.documentNumber : null,
         caseKindCodebookId: this.fourthStep.value.caseKindCodebookId,
         isViolence: this.isCheckedViolence,
      };
      return entrance;
   }

   showInfoMessage() {
      this.toastr.info('SNACKBAR.LEGAL_REGISTER_NOT_EXIST');
   }

   /** Get selected row from table, selected family member */
   onRowSelected(row: any) {
      if (this.selectedMember !== null) {
         this.selectedMember.highlighted = !this.selectedMember.highlighted;
      }
      row.highlighted = !row.highlighted;
      this.selectedMember = row;
      this.isMemberSelected = true;
   }

   /** Remove selected family member from table and refresh table content */
   removeFamilyMember() {
      const index = this.members.findIndex(x => x.name === this.selectedMember.name);
      this.members.splice(index, 1);
      this.members = this.members.slice();
      const rNum = this.members.findIndex(x => x.regNumber === this.selectedMember.regNumber);
      this.memberRegs.splice(rNum, 1);
      this.memberRegs = this.memberRegs.slice();
      this.fourthStep.patchValue({
         familyRegNumb: this.memberRegs,
      });
      this.isMemberSelected = false;
   }

   autoSelectOffice() {
      autoSelect(this.officeCodebook, 'orgUnitCodebookId', 'name', this.fourthStep);
   }

   /** Get selected row from table, selected family member */
   selectedVCommiter(row: any) {
      if (this.selectedViolenceCommiter !== null) {
         this.selectedViolenceCommiter.highlighted = !this.selectedViolenceCommiter.highlighted;
      }
      row.highlighted = !row.highlighted;
      this.selectedViolenceCommiter = row;
      this.isViolenceCommitterSelected = true;
   }

   /** Remove selected violence committer from table and refresh table content */
   removeViolenceCommitter() {
      const index = this.violenceCommitters.findIndex(x => x.name === this.selectedViolenceCommiter.name);
      this.violenceCommitters.splice(index, 1);
      this.violenceCommitters = this.violenceCommitters.slice();
      this.isViolenceCommitterSelected = false;
   }

   printPreview() {
      const entrance = this.createEntranceObject();
      this.entranceService.printPreview(entrance);
   }

   /* Validacija podataka o podnosiocu podneska ukoliko je podnosilac anoniman.
    * U tom slucaju polja: ime podnosioca, prezime, telefon, adresa ne treba da budu obavezna
    */
   setConditionallyAnonymousValidators() {
      const nameOfSubmitter = this.firstStep.get('nameOfSubmitter');
      const surnameOfSubmitter = this.firstStep.get('surnameOfSubmitter');
      const phoneOfSubmitter = this.firstStep.get('phoneOfSubmitter');
      const addressOfSubmitter = this.firstStep.get('addressOfSubmitter');

      this.firstStep.get('kindOfSubmission').valueChanges.subscribe(item => {
         // Broj 6 je ID za opciju anonimno u sifrarniku
         if (item.id !== 6) {
            nameOfSubmitter.setValidators([Validators.required]);
            // Ako se radi o pravnom licu, ono nema prezime tako da ne treba da stoji validator
            if (this.submission?.submitter !== null) {
               if (this.submission?.submitter.csrId !== null) {
                  surnameOfSubmitter.setValidators([Validators.required]);
               } else {
                  surnameOfSubmitter.setValidators(null);
               }
            }
            phoneOfSubmitter.setValidators([]);
            //  addressOfSubmitter.setValidators([Validators.required]);
         } else {
            nameOfSubmitter.setValidators(null);
            surnameOfSubmitter.setValidators(null);
            phoneOfSubmitter.setValidators(null);
            addressOfSubmitter.setValidators(null);
         }
         nameOfSubmitter.updateValueAndValidity();
         surnameOfSubmitter.updateValueAndValidity();
         phoneOfSubmitter.updateValueAndValidity();
         addressOfSubmitter.updateValueAndValidity();
      });
   }

   isRequiredSurname() {
      if (this.firstStep.get('kindOfSubmission').value.id !== 6) {
         if (this.submission != null) {
            if (this.submission.submitter != null) {
               if (this.submission.submitter.csrId == null) {
                  return false;
               }
            }
         }
         return true;
      }
      return false;
   }

   /**
    * Prikazivanje filtriranih vrijednosti u zeljenom formatu
    */
   displayCustomFormat(caseClass: any): string {
      return caseClass ? caseClass.code + ' | ' + caseClass.title : '';
   }

   displayCustomFormatOfficeCodebook(element: any) {
      return element ? element.name : '';
   }

   showOrgUnit(caseClass: any): string {
      return caseClass ? caseClass.name : '';
   }

   /**
    * Filtriranje sifrarnika klasifikacija predmeta po klasifikacionom broju i po nazivu
    */
   private _filter(value: string, what: AutoCompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutoCompleteFilterEnum.CASE_CLASSIFICATION:
            return this.caseClassifications.filter(option => option.title.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
         case AutoCompleteFilterEnum.OFFICE_CODEBOOK:
            return sortByFirstLetter(
               this.officeCodebook.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }

   compareSubmissionType(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   decisionChangeFourthStep() {
      const choice = this.fourthStep.controls.entranceDecision.value.id;
      if (choice === 6) {
         this.fourthStep.controls.decisionAddInfo.enable();
         this.fourthStep.controls.caseClassification.disable();
         this.fourthStep.controls.caseClassification.setValue('');
         this.fourthStep.controls.caseKindCodebookName.setValue('');
         this.fourthStep.controls.caseKindCodebookId.setValue('');
      } else if (choice === 5) {
         this.fourthStep.controls.decisionAddInfo.disable();
         this.fourthStep.controls.caseClassification.disable();
         this.fourthStep.controls.caseClassification.setValue('');
         this.fourthStep.controls.decisionAddInfo.setValue('');
         this.fourthStep.controls.caseKindCodebookName.setValue('');
         this.fourthStep.controls.caseKindCodebookId.setValue('');
      } else {
         this.fourthStep.controls.caseClassification.enable();
         this.fourthStep.controls.decisionAddInfo.disable();
         this.fourthStep.controls.decisionAddInfo.setValue('');
      }
   }

   /** Prihvatanje na obradu podneska koji je stigao iz pisarnice */
   acceptSubmission() {
      if (this.submission != null) {
         this.isVisibleAcceptSubmission = false;
         this.submissionService.acceptSubmission(this.submission.id).subscribe(result => {
            this.dataService.acceptedSubmission.next(true);
            this.submission = result;
            this.toastr.success('SNACKBAR.ACCEPTED_SUBMISSION');
         });
      }
   }

   /* Prikazivanje i sakrivanje koraka sa podacima o izvrsitelju nasilja.
       Kad se korak sakrije prethodno uneseni podaci ce biti obrisati, tj. korak ce biti resetovan. */
   toggle(event: MatSlideToggleChange) {
      if (event.checked === true) {
         this.violenceStep.enable();
         if (this.violenceKindCodebook.length == 0) {
            this.codebookService.getViolenceKindCodebook().subscribe(result => {
               this.violenceKindCodebook = result;
            });
         }
         if (this.availabilityOfVictimToPerpetratorCodebook.length == 0) {
            this.codebookService.getAvailabilityVictimToPerpetratorCodebook().subscribe(result => {
               this.availabilityOfVictimToPerpetratorCodebook = result;
            });
         }
         if (this.emergencyMeasuresCodebook.length == 0) {
            this.codebookService.getEmergencyMeasuresCodebook().subscribe(result => {
               this.emergencyMeasuresCodebook = result;
            });
         }
      } else {
         this.violenceStep.disable();
         this.violenceCommitters = [];
         this.violenceStep.reset();
      }
   }

   getCodebooks() {
      this.codebookService.getSocialCaseClassificationCodebook().subscribe(result => {
         this.caseClassifications = result;
      });
      this.codebookService.getSubmissionTypeCodebook().subscribe(result => {
         this.kindOfSubmissionOptions = result;
      });
      this.codebookService.getEnrollmentEvidentionCodebook().subscribe(result => {
         this.enrollmentEvidentions = result;
      });
      this.codebookService.getEntranceDecisionCodebook().subscribe(result => {
         this.entranceDecisions = result;
      });
      this.codebookService.getSubmissionKindCodebook().subscribe(result => {
         this.submissionsOptions = result;
      });

      this.codebookService.getOfficeCodebookByCsrId(this.csrId).subscribe(result => {
         this.officeCodebook = result;
         // Prijemna kancelarija NE treba da bude prikazana u ucitanom sifrarniku.
         this.officeCodebook.forEach((value, index) => {
            if (value.id === 681) {
               this.officeCodebook.splice(index, 1);
            }
         });
         this.filteredOfficeCodebookOptions = this.fourthStep.controls.orgUnitCodebookId.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name, AutoCompleteFilterEnum.OFFICE_CODEBOOK) : this.officeCodebook.slice()))
         );
      });

      this.codebookService.getCaseTypeCodebook().subscribe(result => {
         this.caseTypeCodebook = result;
      });

      this.codebookService.getCaseKindCodebook().subscribe(result => {
         this.caseKindCodebook = result;
      });
   }
   checkClasificationCase(fast: boolean) {
      if (fast === false) {
         setTimeout(() => {
            this.checkClassification();
         }, 300);
      } else {
         this.checkClassification();
      }
   }

   checkClassification() {
      const temp = this.fourthStep;
      const caseClassifications = this.caseClassifications;
      const caseKindCodebook = this.caseKindCodebook;
      if (temp.value.entranceDecision.id === 1 || temp.value.entranceDecision.id === 2 || temp.value.entranceDecision.id === 3 || temp.value.entranceDecision.id === 4) {
         if (temp.value.caseClassification.code === undefined || temp.value.caseClassification.code === null) {
            const founded = caseClassifications.filter(
               caseClass => caseClass.title.toLowerCase().includes(temp.value.caseClassification) || caseClass.code.toLowerCase().includes(temp.value.caseClassification)
            );
            if (founded.length === 0) {
               temp.controls.caseClassification.setErrors({ notValid: true });
               return;
            } else {
               temp.controls.caseClassification.setValue(founded[0]);
               temp.controls.caseClassification.setErrors(null);
            }
         }
         const caseKindFromClassification = temp.controls.caseClassification.value.code.split('-')[0].trim();
         const caseKindCodebookChoice = caseKindCodebook.filter(caseKind => {
            const caseKindIdFormated = caseKind.kind.length === 2 ? '0' + caseKind.kind : caseKind.kind;
            return caseKindIdFormated === caseKindFromClassification;
         });
         temp.controls.caseKindCodebookName.setValue(caseKindCodebookChoice[0].name);
         temp.controls.caseKindCodebookId.setValue(caseKindCodebookChoice[0].id);
      }
   }

   private formatAddress(address: any): string {
      return address === null
         ? null
         : String(address.town === null ? '' : address.town) +
              String(address.street === null ? '' : ', ' + address.street) +
              String(address.number === null ? '' : ' ' + address.number) +
              String(address.subnumber === null ? '' : '/' + address.subnumber);
   }
}
