import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class StatisticsService {
   private btnDisabled: BehaviorSubject<boolean>;
   constructor(private httpClient: HttpClient, private app: AppService, private toastr: ToastrImplService) {
      this.btnDisabled = new BehaviorSubject<boolean>(false);
   }

   private managerError() {
      this.setBtnStatus(false);
      this.toastr.error('STATISTICS.ERROR');
   }
   getBtnStatus(): Observable<boolean> {
      return this.btnDisabled.asObservable();
   }
   setBtnStatus(value): void {
      this.btnDisabled.next(value);
   }
   getWorkerStatistics(csrId, workerId, reportingPeriod, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getWorkerStatistics + csrId + '/' + workerId, reportingPeriod, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getOfficeStatistics(csrId, officeId, reportingPeriod, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getOfficeStatistics + csrId + '/' + officeId, reportingPeriod, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getCenterStatistics(reportingPeriod, csrId, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getCenterStatistics + csrId, reportingPeriod, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getBasicStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getBasicStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.setBtnStatus(false);
            this.downloadFile(data, filename);
         },
         error => {
            this.managerError();
         }
      );
   }

   getControlStats(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getControlStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getGenerallStats(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getGeneralStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }
   getDocumentsStats(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      return this.httpClient.post(this.app.getUrl() + environment.getDocumentStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.setBtnStatus(false);
            this.downloadFile(data, filename);
         },
         error => {
            this.managerError();
         }
      );
   }

   getSocialCaseStats(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      return this.httpClient.post(this.app.getUrl() + environment.getSocialCaseStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.setBtnStatus(false);
            this.downloadFile(data, filename);
         },
         error => {
            this.managerError();
         }
      );
   }

   getNspStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      return this.httpClient.post(this.app.getUrl() + environment.getNspStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getActivityStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getActivityStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getJnpStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getJnpStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getJnpStatisticsTxt(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getJnpStatisticsTxt, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFileTxt(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getCaseOutcomeStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getCaseOutcomeStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getTerminationReasonStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getTerminationReasonStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getTrackingListStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getTrackingListStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getViolenceStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getViolenceStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
         },
         error => {
            this.managerError();
         }
      );
   }

   getUsersInCenterByCsrIds(csrId: any) {
      return this.httpClient.post(this.app.getUrl() + environment.getAllUsersByCenterIdUrl, { csrId }) as Observable<any>;
   }

   private downloadFile(content: any, filename: any): void {
      const parts = [];
      parts.push(content);
      const file = new Blob(parts, { type: 'csv/html;charset=utf8' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = fileURL;
      a.download = filename + '.csv';
      a.click();
      window.URL.revokeObjectURL(fileURL);
      a.remove();
   }

   private downloadFileTxt(content: any, filename: any): void {
      const parts = [];
      parts.push(content);
      const file = new Blob(parts, { type: 'octet/stream' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = fileURL;
      a.download = filename + '.zip';
      a.click();
      window.URL.revokeObjectURL(fileURL);
      a.remove();
   }

   getCollectionStatistics(dto, filename) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.setBtnStatus(true);
      this.httpClient.post(this.app.getUrl() + environment.getCollectionStatistics, dto, { responseType: 'blob', headers }).subscribe(
         data => {
            this.downloadFile(data, filename);
            this.setBtnStatus(false);
            this.toastr.warning('STATISTICS.COLLECTION_STATS.NOT_IMPORTED_COLLECTION',"",20000);
         },
         error => {
            this.managerError();
         }
      );
   }
}
