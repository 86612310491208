import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { AlfrescoService } from 'src/services/alfresco.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { VsService } from 'src/services/vs.service';

@Component({
   selector: 'app-vs-lawyer',
   templateUrl: './vs-lawyer.component.html',
   styleUrls: ['./vs-lawyer.component.scss'],
})
export class VsLawyerComponent implements OnInit {
   @Input() subjectId: any;
   @Input() caseId: any;
   @Input() caseData: any;
   @Input() caseName: any;
   @Input() hasProblem: boolean;
   @Input() title: string;
   @Input() noRegistrationId: any;
   lawyerFormVS: FormGroup;
   decisionTypes: [];
   fileToUpload: File = null;
   documentOptions: any;
   closingCaseData = null;
   decisionMakerOptions: any;
   @ViewChild('submitButton') submitButton: any;
   isSubmited = false;
   terminationReasons: any = [];
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private vsService: VsService,
      private caseService: CaseService,
      public dialog: MatDialog,
      private alfresco: AlfrescoService,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private toastr: ToastrImplService,
      private caseProblemRecordService: CaseProblemRecordService,
      private expedition: ExpeditionService,
      private dataSharingService: DataSharingService
   ) {}

   ngOnInit(): void {
      this.initializeLawyerForm();
      this.getDecisionMakerCodebook();
      this.patchValues();
      this.codebookService.getDecisionTypeCodebook().subscribe(result => {
         this.decisionTypes = result;
         if (this.title.toLowerCase().includes('престанак')) {
            this.decisionTypes.splice(0, 1);
         }
      });
      this.getDocumentsByTemplate();
      this.lawyerFormVS.controls.dateOfCaseCreation.setValue(new Date(this.caseData.dateOfCreation.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')));
      this.lawyerFormVS.controls.dateOfCaseCreation.disable();
   }

   initializeLawyerForm() {
      this.lawyerFormVS = this.formBuilder.group({
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         caseId: this.caseId,
         dateOfCaseCreation: [''],
         dateOfDecisionCreation: new FormControl({
            value: new Date(),
            disabled: true,
         }),
         decisionType: ['', [Validators.required]],
         subjectId: this.subjectId,
         terminationOfRights: [''],
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         terminationReason: [''],
      });
   }

   patchValues() {
      this.closingCaseData = this.caseData?.closingVs;
      this.lawyerFormVS = this.formBuilder.group({
         caseId: this.caseId,
         subjectId: this.subjectId,
         dateOfDecisionCreation: new FormControl({
            value: new Date(),
            disabled: true,
         }),
         dateOfCaseCreation: new FormControl({
            value: new Date(this.caseData.dateOfCreation),
            disabled: true,
         }),
         decisionType: this.closingCaseData?.decisionTypeCodebook,
         terminationOfRights: this.closingCaseData?.reason,
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[this.caseData.decisionMaker?.id - 1],
         decisionMakerName: this.caseData.decisionMakerName,
         decisionMakerTitle: this.caseData.decisionMakerTitle,
         decisionMakerDescription: this.caseData.decisionMakerDescription,
         terminationReason: this.closingCaseData?.terminationReason,
      });
      this.changeDecision(this.closingCaseData?.decisionTypeCodebook);
   }
   getDocumentsByTemplate() {
      this.alfresco.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
         this.documentOptions = result;
      });
   }
   async submitConclusion() {
      const addNewRecordRequered = await this.addNewRecord(this.lawyerFormVS.controls.decisionType.value.id);
      if (addNewRecordRequered) {
         this.isSubmited = true;
         const body = { ...this.createLawyerDecisionBody(), isTempSave: false };
         this.vsService.saveLawyerDecision(body).subscribe(
            (res: any) => {
               this.caseService.addConclusionFinishCase(this.lawyerFormVS.get('lawyerFile').value, this.subjectId, this.caseData.id).subscribe(
                  (documentId: string) => {
                     this.toastr.success('SNACKBAR.SOLUTION_CREATED');
                     this.localStorageService.set('case', true);
                     this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'vs');
                     this.dataSharingService.cases.next(true);
                  },
                  error => {
                     manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
                     this.isSubmited = false;
                     this.submitButton.disabled = false;
                  }
               );
            },
            error => {
               manageError(this.toastr, ErrorType.SAVE_DATA);
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         );
      } else {
         this.submitButton.disabled = false;
         this.isSubmited = false;
      }
   }
   private createLawyerDecisionBody() {
      const body = {
         id: this.closingCaseData ? this.closingCaseData.id : null,
         decisionTypeCodebook: this.lawyerFormVS.controls.decisionType.value,
         socialCaseId: this.caseId,
         reason: this.lawyerFormVS.controls.terminationOfRights !== undefined ? this.lawyerFormVS.controls.terminationOfRights.value : null,
         decisionMaker: this.lawyerFormVS.value.decisionMaker ? this.lawyerFormVS.value.decisionMaker : null,
         decisionMakerDescription: this.lawyerFormVS.value.decisionMaker?.id === 2 ? this.lawyerFormVS.value.decisionMakerDescription : '',
         decisionMakerName: this.lawyerFormVS.value.decisionMakerName ? this.lawyerFormVS.value.decisionMakerName : '',
         decisionMakerTitle: this.lawyerFormVS.value.decisionMakerTitle ? this.lawyerFormVS.value.decisionMakerTitle : '',
         terminationReason: this.lawyerFormVS.controls.decisionType.value?.id === 3 && this.lawyerFormVS.value.terminationReason !== '' ? this.lawyerFormVS.value.terminationReason : null,
      };
      return body;
   }
   saveCurrentData() {
      const body = { ...this.createLawyerDecisionBody(), isTempSave: true };
      this.vsService.saveLawyerDecision(body).subscribe(
         (closingVsId: number) => {
            if (closingVsId) {
               this.closingCaseData = { ...this.closingCaseData, id: closingVsId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.lawyerFormVS.markAsPristine();
            }
         },
         error => {
            this.submitButton.disabled = false;
            this.isSubmited = false;
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.lawyerFormVS.get('lawyerFile').setValue(this.fileToUpload);
         this.lawyerFormVS.get('fileName').setValue(this.fileToUpload.name);
      }
   }
   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }
   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfresco.downloadDocumentByUrl(body);
   }
   async addNewRecord(decision: any): Promise<boolean> {
      if (decision == null || decision === 1 || decision === 3) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.lawyerFormVS.controls.dateOfCaseCreation.value,
               amount: '',
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }

   getDecisionMakerCodebook() {
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.caseData?.decisionMaker) {
            this.lawyerFormVS.controls.decisionMaker.setValue(result[0]);
            this.lawyerFormVS.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.lawyerFormVS.controls.decisionMaker.setValue(result[this.caseData?.decisionMaker.id - 1]);
            this.lawyerFormVS.controls.decisionMakerTitle.setValue(this.caseData?.decisionMakerTitle);
         }
      });
   }
   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.lawyerFormVS.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.lawyerFormVS.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.lawyerFormVS.controls.decisionMakerDescription.setValue('');
         this.lawyerFormVS.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }
   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(this.caseData, !this.lawyerFormVS.valid, this.hasProblem);
   }
   changeDecision(decision: any) {
      if (decision?.id === 3 && this.caseData?.socialCaseClassificationCodebook.oldKindMark === 'STAR') {
         this.lawyerFormVS.controls.terminationReason.enable();
         this.lawyerFormVS.controls.terminationReason.setValidators(Validators.required);
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType('STAR').subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.lawyerFormVS.controls.terminationReason.disable();
         this.lawyerFormVS.controls.terminationReason.setErrors(null);
         this.lawyerFormVS.controls.terminationReason.setValue(null);
         this.lawyerFormVS.controls.terminationReason.clearValidators();
      }
   }
}
