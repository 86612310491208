<container kind="midi">
   <page-intro-header title="{{ title + ' ' + (this.submission?.documentNumber !== null ? this.submission?.documentNumber : '')}} ">
      <button *ngIf="isVisibleAcceptSubmission == false || isVisibleAcceptSubmission == null" mat-flat-button color="primary" (click)="printPreview()">{{ "PRINT.PREVIEW" | translate }}</button>
      <button *ngIf="isVisibleAcceptSubmission" mat-flat-button color="primary" (click)="acceptSubmission()">{{ "ENTRANCE.STEP_01.ACCEPT_SUBMISSION" | translate }}</button>
   </page-intro-header>
   <mat-card class="card entrance" *ngIf="!isLoading && ( createManualy || isVisibleAcceptSubmission == false)">
      <mat-slide-toggle color="primary" [(ngModel)]="isCheckedViolence" (change)="toggle($event)" class="toggle">{{ 'ENTRANCE.SHOW_VIOLENCE_STEP'| translate }}</mat-slide-toggle>
      <mat-vertical-stepper #stepper [linear]="true">
         <mat-step [stepControl]="firstStep">
            <form class="form" [formGroup]="firstStep">
               <ng-template matStepLabel>{{ "ENTRANCE.STEP_01.TITLE" | translate }}</ng-template>
               <field-holder kind="4-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.CSR" | translate }}</mat-label>
                     <input matInput type="text" formControlName="center" required readonly autocomplete="off" />
                     <mat-error *ngIf="firstStep.get('center').hasError('required')">{{ "ENTRANCE.STEP_01.CSR_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="submission" appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.NUMBER" | translate }}</mat-label>
                     <input matInput type="text" formControlName="submissionNumer" readonly autocomplete="off" />
                     <mat-error *ngIf="firstStep.get('submissionNumer').hasError('required')">{{ "ENTRANCE.STEP_01.NUMBER_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="submission==null" appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.NUMBER" | translate }}</mat-label>
                     <input matInput type="text" formControlName="entranceIdentity" readonly autocomplete="off" />
                     <mat-error *ngIf="firstStep.get('entranceIdentity').hasError('required')">{{ "ENTRANCE.STEP_01.NUMBER_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.DATE" | translate }}</mat-label>
                     <input matInput [matDatepicker]="picker" formControlName="dateOfSubmission" disabled readonly required autocomplete="off" [max]="maxDate" />
                     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                     <mat-datepicker #picker></mat-datepicker>
                     <mat-error *ngIf="firstStep.get('dateOfSubmission').hasError('required')">{{ "ENTRANCE.STEP_01.DATE_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.TIME" | translate }}</mat-label>
                     <input matInput type="text" formControlName="timeOfSubmission" required readonly autocomplete="off" />
                     <mat-hint>{{ "ENTRANCE.STEP_01.TIME_HINT" | translate }}</mat-hint>
                     <mat-error *ngIf="firstStep.get('timeOfSubmission').hasError('required')">{{ "ENTRANCE.STEP_01.TIME_ERROR" | translate }}</mat-error>
                  </mat-form-field>
               </field-holder>
               <field-holder kind="3-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.SUBMISSION" | translate }}</mat-label>
                     <mat-select formControlName="typeOfSubmission" [compareWith]="compareSubmissionType" required>
                        <mat-option *ngFor="let typeOfSubmission of submissionsOptions" [value]="typeOfSubmission">{{ typeOfSubmission.receivingMethod }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="firstStep.get('typeOfSubmission').hasError('required')">{{ "ENTRANCE.STEP_01.SUBMISSION" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.PREVIOUS_EVIDENTION" | translate }}</mat-label>
                     <mat-select formControlName="enrollmentEvidention" [compareWith]="compareSubmissionType" required>
                        <mat-option *ngFor="let enrollmentEv of enrollmentEvidentions" [value]="enrollmentEv">{{ enrollmentEv.title }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="firstStep.get('enrollmentEvidention').hasError('required')">{{ "ENTRANCE.STEP_01.PREVIOUS_EVIDENTION_ERROR" | translate }}</mat-error>
                  </mat-form-field>

                  <ng-container *ngIf="firstStep.get('enrollmentEvidention').value !== null">
                     <mat-form-field appearance="outline" *ngIf="firstStep.get('enrollmentEvidention').value.id === 4">
                        <mat-label>{{ "ENTRANCE.STEP_01.CSR_REASON" | translate }}</mat-label>
                        <input matInput type="text" formControlName="CSRReason" autocomplete="off" [required]="firstStep.get('enrollmentEvidention').value.id === 4" />
                        <mat-error *ngIf="fourthStep.get('decisionAddInfo').hasError('required')">{{ "ENTRANCE.STEP_04.TRANSFER_TO_ERROR" | translate }}</mat-error>
                     </mat-form-field>
                  </ng-container>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.KIND_OF_SUBMISSION" | translate }}</mat-label>
                     <mat-select formControlName="kindOfSubmission" [disabled]="isSubmission" [compareWith]="compareSubmissionType" required>
                        <mat-option *ngFor="let subType of kindOfSubmissionOptions" [value]="subType">{{ subType.title }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="firstStep.get('kindOfSubmission').hasError('required')">{{ "ENTRANCE.STEP_01.KIND_OF_SUBMISSION_ERROR" | translate }}</mat-error>
                  </mat-form-field>
               </field-holder>
               <form-subgroup-divider></form-subgroup-divider>
               <div
                  class="button-add-holder"
                  *ngIf="
              firstStep.get('kindOfSubmission').value.id === 1 ||
              firstStep.get('kindOfSubmission').value.id === 2 ||
              firstStep.get('kindOfSubmission').value.id === 4 ||
              firstStep.get('kindOfSubmission').value.id === 3 ||
              firstStep.get('kindOfSubmission').value.id === 7 || 
              firstStep.get('kindOfSubmission').value.id === 8 || 
              firstStep.get('kindOfSubmission').value.id === 9 || 
              firstStep.get('kindOfSubmission').value.id === 10 || 
              firstStep.get('kindOfSubmission').value.id === 11
            "
               >
                  <button
                     mat-flat-button
                     color="primary"
                     type="button"
                     [disabled]="isSubmission"
                     (click)="
                firstStep.get('kindOfSubmission').value.id === 4 || 
                firstStep.get('kindOfSubmission').value.id === 9 || 
                firstStep.get('kindOfSubmission').value.id === 10 || 
                firstStep.get('kindOfSubmission').value.id === 11
                  ? importSubmitterData('pravna_lica')
                  : importSubmitterData('fizicka_lica')
              "
                  >
                     <mat-icon>person_add</mat-icon>
                     {{ "ENTRANCE.STEP_01.ADD_SUBJECT" | translate }}
                  </button>
               </div>
               <field-holder kind="4-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.FIRST_NAME" | translate }}</mat-label>
                     <input
                        matInput
                        type="text"
                        formControlName="nameOfSubmitter"
                        [readonly]="isSubmission"
                        required
                        autocomplete="off"
                        [required]="firstStep.get('kindOfSubmission').value.id !== 6"
                        maxlength="100"
                     />
                     <mat-error *ngIf="firstStep.get('nameOfSubmitter').hasError('required')">{{ "ENTRANCE.STEP_01.FIRST_NAME_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.LAST_NAME" | translate }}</mat-label>
                     <input matInput type="text" formControlName="surnameOfSubmitter" [readonly]="isSubmission" maxlength="100" autocomplete="off" [required]="isRequiredSurname()" />

                     <mat-error *ngIf="firstStep.get('surnameOfSubmitter').hasError('required')">{{ "ENTRANCE.STEP_01.LAST_NAME_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.PHONE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="phoneOfSubmitter" [readonly]="isSubmission" maxlength="30" autocomplete="off" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.ADDRESS" | translate }}</mat-label>
                     <input matInput type="text" formControlName="addressOfSubmitter" [readonly]="isSubmission" maxlength="100" autocomplete="off" />
                     <mat-error *ngIf="firstStep.get('addressOfSubmitter').hasError('required')">{{ "ENTRANCE.STEP_01.ADDRESS_ERROR" | translate }}</mat-error>
                  </mat-form-field>
               </field-holder>
               <field-holder kind="2-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.OTHER_SERVICE" | translate }}</mat-label>
                     <textarea matInput type="text" formControlName="otherService" autocomplete="off"></textarea>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_01.REASON_FOR_SUBMIT" | translate }}</mat-label>
                     <textarea matInput type="text" formControlName="reasonForSubmit" required autocomplete="off"></textarea>
                     <mat-error *ngIf="firstStep.get('reasonForSubmit').hasError('required')">{{ "ENTRANCE.STEP_01.REASON_FOR_SUBMIT_ERROR" | translate }}</mat-error>
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button matStepperNext mat-stroked-button color="primary" [disabled]="!firstStep.valid" class="form-footer__button">{{ "ENTRANCE.NEXT_STEP" | translate }}</button>
               </form-footer>
            </form>
         </mat-step>
         <mat-step [stepControl]="secondStep">
            <form class="form" [formGroup]="secondStep">
               <ng-template matStepLabel>{{ "ENTRANCE.STEP_02.TITLE" | translate }}</ng-template>
               <div class="button-add-holder">
                  <button mat-flat-button color="primary" (click)="importSubjectData()">
                     <mat-icon>person_add</mat-icon>
                     {{ "ENTRANCE.STEP_02.ADD_SUBJECT" | translate }}
                  </button>
               </div>
               <field-holder kind="3-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.FIRST_NAME" | translate }}</mat-label>
                     <input matInput type="text" formControlName="firstName" required autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.LAST_NAME" | translate }}</mat-label>
                     <input matInput type="text" formControlName="lastName" required autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.GENDER" | translate }}</mat-label>
                     <input matInput type="text" formControlName="gender" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <field-holder kind="3-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.DATE_OF_BIRTH" | translate }}</mat-label>
                     <input matInput type="text" formControlName="dateOfBirth" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.PLACE_OF_BIRTH" | translate }}</mat-label>
                     <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.SUBJECT_ID" | translate }}</mat-label>
                     <input matInput type="text" formControlName="jmbg" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <form-subgroup-divider></form-subgroup-divider>
               <field-holder kind="2-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.PERMANENT_RESIDENCE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="permanentResidence" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.PHONE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="phone" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <field-holder kind="2-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.RESIDENCE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="residence" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.MOBILE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="mobile" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <form-subgroup-divider></form-subgroup-divider>
               <field-holder kind="3-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.NATIONALITY" | translate }}</mat-label>
                     <input matInput type="text" formControlName="nationality" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.CITIZENSHIP" | translate }}</mat-label>
                     <input matInput type="text" formControlName="citizenship" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.NATIVE_LANGUAGE" | translate }}</mat-label>
                     <input matInput type="text" formControlName="nativeLanguage" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <form-subgroup-divider></form-subgroup-divider>
               <field-holder kind="4-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.EDUCATION" | translate }}</mat-label>
                     <input matInput type="text" formControlName="education" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.ABLE_TO_WORK" | translate }}</mat-label>
                     <input matInput type="text" formControlName="ableToWork" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.OCCUPATION" | translate }}</mat-label>
                     <input matInput type="text" formControlName="occupation" autocomplete="off" readonly />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.EMPLOYMENT" | translate }}</mat-label>
                     <input matInput type="text" formControlName="employment" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <form-subgroup-divider></form-subgroup-divider>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_02.USEFUL_DATA" | translate }}</mat-label>
                     <textarea matInput type="text" formControlName="usefulData" autocomplete="off"></textarea>
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button matStepperPrevious mat-stroked-button color="primary" class="form-footer__button form-footer__button--secondary">{{ "ENTRANCE.PREVIOUS_STEP" | translate }}</button>
                  <button matStepperNext mat-stroked-button color="primary" [disabled]="!secondStep.valid" class="form-footer__button">{{ "ENTRANCE.NEXT_STEP" | translate }}</button>
               </form-footer>
            </form>
         </mat-step>
         <mat-step [stepControl]="thirdStep">
            <form class="form" [formGroup]="thirdStep">
               <ng-template matStepLabel>{{ "ENTRANCE.STEP_03.TITLE" | translate }}</ng-template>
               <table mat-table [dataSource]="members" #mytable class="table-responsive-wrapper" *ngIf="members.length > 0">
                  <ng-container matColumnDef="regNumber">
                     <th mat-header-cell *matHeaderCellDef>{{ 'FAMILY_MEMBERS.REG_NUMBER' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.regNumber || element.registrationId }}</td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                     <th mat-header-cell *matHeaderCellDef>{{ 'FAMILY_MEMBERS.FIRST_LAST_NAME' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                  </ng-container>
                  <ng-container matColumnDef="relationship">
                     <th mat-header-cell *matHeaderCellDef>{{ 'FAMILY_MEMBERS.RELATIONSHIP' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.relationship.title }}</td>
                  </ng-container>
                  <ng-container matColumnDef="yearOfBirth">
                     <th mat-header-cell *matHeaderCellDef>{{ 'FAMILY_MEMBERS.BIRTH_YEAR' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.yearOfBirth }}</td>
                  </ng-container>
                  <ng-container matColumnDef="qualificationEmployment">
                     <th mat-header-cell *matHeaderCellDef>{{ 'FAMILY_MEMBERS.QUALIFICATION_EMPLOYMENT' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.qualificationEmployment }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="members__row-header"></tr>
                  <tr
                     mat-row
                     *matRowDef="let row; columns: displayedColumns"
                     (click)="onRowSelected(row)"
                     [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
                     (mouseover)="row.hovered = true"
                     (mouseout)="row.hovered = false"
                  ></tr>
               </table>
               <div class="button-add-holder">
                  <button mat-flat-button mat-stroked-button color="primary" (click)="openFamilyMembersDialog()" class="form-footer__button form-footer__button--secondary">
                     <mat-icon>person_add</mat-icon>
                     {{ "ENTRANCE.STEP_03.ADD_MEMBER" | translate }}
                  </button>
                  <button mat-flat-button mat-stroked-button color="primary" (click)="addFamilyMember()" class="form-footer__button form-footer__button--secondary">
                     <mat-icon>person_add</mat-icon>
                     {{ "ENTRANCE.STEP_03.ADD_NEW_MEMBER" | translate }}
                  </button>
                  <button mat-flat-button mat-stroked-button color="primary" (click)="removeFamilyMember()" [disabled]="!isMemberSelected">
                     <mat-icon>person_remove</mat-icon>
                     {{ "ENTRANCE.STEP_03.REMOVE_MEMBER" | translate }}
                  </button>
               </div>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_03.NOTE" | translate }}</mat-label>
                     <input
                        matInput
                        type="text"
                        formControlName="note"
                        autocomplete="off"
                        matTooltip="{{
              'ENTRANCE.STEP_03.NOTE_TOOLTIP' | translate}}"
                     />
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button matStepperPrevious mat-stroked-button color="primary" class="form-footer__button form-footer__button--secondary">{{ "ENTRANCE.PREVIOUS_STEP" | translate }}</button>
                  <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">{{ "ENTRANCE.NEXT_STEP" | translate }}</button>
               </form-footer>
            </form>
         </mat-step>
         <mat-step [stepControl]="violenceStep" *ngIf="isCheckedViolence">
            <form class="form" [formGroup]="violenceStep">
               <ng-template matStepLabel>{{ "ENTRANCE.VIOLENCE_STEP.TITLE" | translate }}</ng-template>
               <table mat-table [dataSource]="violenceCommitters" #mytable class="table-responsive-wrapper" *ngIf="violenceCommitters.length > 0">
                  <ng-container matColumnDef="subjectId">
                     <th mat-header-cell *matHeaderCellDef>{{ 'ENTRANCE.VIOLENCE_STEP.SUBJECT_ID' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.subjectId }}</td>
                  </ng-container>
                  <ng-container matColumnDef="regNumber">
                     <th mat-header-cell *matHeaderCellDef>{{ 'ENTRANCE.VIOLENCE_STEP.REG_NUMBER' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.regNumber }}</td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                     <th mat-header-cell *matHeaderCellDef>{{ 'ENTRANCE.VIOLENCE_STEP.FIRST_LAST_NAME' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                  </ng-container>
                  <ng-container matColumnDef="relationship">
                     <th mat-header-cell *matHeaderCellDef>{{ 'ENTRANCE.VIOLENCE_STEP.RELATIONSHIP' | translate }}</th>
                     <td mat-cell *matCellDef="let element">{{ element.relationship.title }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="violenceCommitterColumns" class="members__row-header"></tr>
                  <tr
                     mat-row
                     *matRowDef="let row; columns: violenceCommitterColumns"
                     (click)="selectedVCommiter(row)"
                     [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
                     (mouseover)="row.hovered = true"
                     (mouseout)="row.hovered = false"
                  ></tr>
               </table>
               <div class="button-add-holder">
                  <button mat-flat-button mat-stroked-button color="primary" (click)="openViolenceCommitterDialog()" type="button" class="form-footer__button form-footer__button--secondary">
                     <mat-icon>person_add</mat-icon>
                     {{ "ENTRANCE.VIOLENCE_STEP.ADD_REGISTERED_SUBJECT" | translate }}
                  </button>
                  <button mat-flat-button mat-stroked-button color="primary" (click)="removeViolenceCommitter()" type="button" [disabled]="!isViolenceCommitterSelected">
                     <mat-icon>person_remove</mat-icon>
                     {{ "ENTRANCE.VIOLENCE_STEP.REMOVE_SUBJECT" | translate }}
                  </button>
               </div>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.VIOLENCE_STEP.VIOLENCE_KIND" | translate }}</mat-label>
                     <mat-select formControlName="violenceKindCodebookIds" required multiple>
                        <mat-option *ngFor="let opt of violenceKindCodebook" [value]="opt">{{ opt.kind + ' - ' + opt.subKind}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </field-holder>
               <field-holder kind="2-cols-1-2">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.VIOLENCE_STEP.AVAILABILITY_OF_VICTIM_TO_PERPETRATOR" | translate }}</mat-label>
                     <mat-select formControlName="availabilityVictimToPerpetrator">
                        <mat-option *ngFor="let opt of availabilityOfVictimToPerpetratorCodebook" [value]="opt">{{ opt.title}}</mat-option>
                     </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.VIOLENCE_STEP.EMERGENCY_MEASURES" | translate }}</mat-label>
                     <mat-select formControlName="emergencyMeasures">
                        <mat-option *ngFor="let opt of emergencyMeasuresCodebook" [value]="opt">{{ opt.title}}</mat-option>
                     </mat-select>
                  </mat-form-field>
               </field-holder>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.VIOLENCE_STEP.VIOLENCE_DESCRIPTION" | translate }}</mat-label>
                     <input matInput type="text" formControlName="violenceDescription" autocomplete="off" />
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button matStepperPrevious mat-stroked-button color="primary" class="form-footer__button form-footer__button--secondary">{{ "ENTRANCE.PREVIOUS_STEP" | translate }}</button>
                  <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">{{ "ENTRANCE.NEXT_STEP" | translate }}</button>
               </form-footer>
            </form>
         </mat-step>
         <mat-step [stepControl]="fourthStep">
            <form class="form" [formGroup]="fourthStep">
               <ng-template matStepLabel>{{ "ENTRANCE.STEP_04.TITLE" | translate }}</ng-template>
               <field-holder kind="2-cols-1-2">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_04.DECISION" | translate }}</mat-label>
                     <mat-select formControlName="entranceDecision" (selectionChange)="decisionChangeFourthStep()" required>
                        <mat-option *ngFor="let decision of entranceDecisions" [value]="decision">{{ decision.title }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="fourthStep.get('entranceDecision').hasError('required')">{{ "ENTRANCE.STEP_04.DECISION_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" *ngIf="fourthStep.get('entranceDecision').value.id === 6">
                     <mat-label>{{ "ENTRANCE.STEP_04.TRANSFER_TO" | translate }}</mat-label>
                     <input matInput type="text" formControlName="decisionAddInfo" autocomplete="off" required />
                     <mat-error *ngIf="fourthStep.get('decisionAddInfo').hasError('required')">{{ "ENTRANCE.STEP_04.TRANSFER_TO_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field
                     appearance="outline"
                     *ngIf="
                  fourthStep.get('entranceDecision').value.id === 1 ||
                  fourthStep.get('entranceDecision').value.id === 2 ||
                  fourthStep.get('entranceDecision').value.id === 3 ||
                  fourthStep.get('entranceDecision').value.id === 4
              "
                  >
                     <mat-label>{{ "ENTRANCE.STEP_04.CASE_CLASSIFICATION" | translate }}</mat-label>
                     <input type="text" matInput formControlName="caseClassification" (focusout)="checkClasificationCase(false) " [matAutocomplete]="auto" required />
                     <mat-autocomplete #auto="matAutocomplete" for="caseClassification" [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option.code}} | {{option.title}}</mat-option>
                     </mat-autocomplete>
                     <mat-error *ngIf="fourthStep.get('caseClassification').hasError('required')||fourthStep.get('caseClassification').hasError('notValid')">
                        {{ "ENTRANCE.STEP_04.CASE_CLASSIFICATION_ERROR" | translate }}
                     </mat-error>
                  </mat-form-field>
               </field-holder>
               <field-holder kind="2-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_04.ORG_UNIT" | translate }}</mat-label>
                     <input type="text" matInput formControlName="orgUnitCodebookId" [matAutocomplete]="autoOrgUnitCodebookId" (focusout)="autoSelectOffice()" />
                     <mat-autocomplete #autoOrgUnitCodebookId="matAutocomplete" [displayWith]="displayCustomFormatOfficeCodebook">
                        <mat-option *ngFor="let option of filteredOfficeCodebookOptions | async" [value]="option">{{ option.name }}</mat-option>
                     </mat-autocomplete>
                     <mat-error *ngIf="fourthStep.get('orgUnitCodebookId').hasError('required')">{{ "ENTRANCE.STEP_04.ORG_UNIT_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field
                     appearance="outline"
                     *ngIf="
              fourthStep.get('entranceDecision').value.id === 1 ||
              fourthStep.get('entranceDecision').value.id === 2 ||
              fourthStep.get('entranceDecision').value.id === 3 ||
              fourthStep.get('entranceDecision').value.id === 4
              "
                  >
                     <mat-label>{{ "ENTRANCE.STEP_04.CASE_KIND" | translate }}</mat-label>
                     <input type="text" matInput formControlName="caseKindCodebookName" readonly />
                  </mat-form-field>
               </field-holder>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_04.EXPLANATION" | translate }}</mat-label>
                     <textarea matInput type="text" formControlName="conclusion_explanation" autocomplete="off"></textarea>
                     <mat-error
                        *ngIf="
                  fourthStep.get('conclusion_explanation').hasError('required')
                "
                     >
                        {{ "ENTRANCE.STEP_04.EXPLANATION_ERROR" | translate }}
                     </mat-error>
                  </mat-form-field>
               </field-holder>
               <field-holder kind="2-cols">
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_04.SUBJECT_REGISTRATION_NUMBER" | translate }}</mat-label>
                     <input matInput type="text" formControlName="subjectId" autocomplete="off" readonly />
                     <mat-error *ngIf="fourthStep.get('subjectId').hasError('required')">{{ "ENTRANCE.STEP_04.SUBJECT_REGISTRATION_NUMBER_ERROR" | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ "ENTRANCE.STEP_04.MEMBER_REGISTRATION_NUMBER" | translate }}</mat-label>
                     <input matInput type="text" formControlName="familyRegNumb" autocomplete="off" readonly />
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button matStepperPrevious mat-stroked-button color="primary" class="form-footer__button form-footer__button--secondary">{{ "ENTRANCE.PREVIOUS_STEP" | translate }}</button>
                  <button
                     mat-flat-button
                     color="primary"
                     (click)="handleSignature(); submitButton.disabled = true;"
                     [disabled]="!fourthStep.valid"
                     class="form-footer__button form-footer__button--secondary"
                     #submitButton
                  >
                     {{ "ENTRANCE.SAVE" | translate }}
                  </button>
               </form-footer>
            </form>
         </mat-step>
      </mat-vertical-stepper>
   </mat-card>
   <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; height: 100vh">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
   </mat-card>
</container>
