<form [formGroup]="changePasswordForm" class="modal-new-worker">
    <form-group title="{{'PROFILE.PASSWORD_CHANGE' | translate  }}">
        <field-holder>
            <mat-form-field appearance="outline">
                <input matInput [type]="passwordFieldType" formControlName="oldPassword" required />
                <button mat-icon-button matSuffix (click)="togglePasswordVisibility('1')" type="button">
                    <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                <mat-label>{{ 'PROFILE.OLD_PASSWORD' | translate }}</mat-label>
                <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('required')">
                    {{'PROFILE.OLD_PASSWORD_ERROR' | translate}}
                </mat-error>
                <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('pattern')">
                    {{'PROFILE.LETTER_ERROR' | translate}}
                </mat-error>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <input matInput [type]="passwordFieldType2" formControlName="newPassword" required />
                <button mat-icon-button matSuffix (click)="togglePasswordVisibility('2')" type="button">
                    <mat-icon>{{ passwordFieldType2 === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                <mat-label>{{ 'PROFILE.NEW_PASSWORD' | translate }}</mat-label>
                <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
                    {{'PROFILE.NEW_PASSWORD_ERROR' | translate}}
                </mat-error>
                <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">
                    {{'PROFILE.LETTER_ERROR' | translate}}
                </mat-error>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <input matInput [type]="passwordFieldType3" formControlName="newPasswordConfirm" required
                    [errorStateMatcher]="matcher" />
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility('3')" type="button">
                        <mat-icon>{{ passwordFieldType3 === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                      </button>
                <mat-label>{{ 'PROFILE.NEW_PASSWORD_CONFIRM' | translate }}</mat-label>
                <mat-error *ngIf="changePasswordForm.get('newPasswordConfirm').hasError('required')">
                    {{'PROFILE.NEW_PASSWORD_CONFIRM_ERROR' | translate}}
                </mat-error>
                <mat-error *ngIf="changePasswordForm.hasError('notSame') && !changePasswordForm.get('newPasswordConfirm').hasError('required')">
                    {{'PROFILE.NEW_PASSWORD_CONFIRM_ERROR_NOT_SAME' | translate}}
                </mat-error>
                <mat-error *ngIf="changePasswordForm.get('newPasswordConfirm').hasError('pattern')">
                    {{'PROFILE.LETTER_ERROR' | translate}}
                </mat-error>
            </mat-form-field>
        </field-holder>
    </form-group>
</form>
<form-footer>
    <button class="form-footer__button form-footer__button--secondary" (click)="closeDialog()" mat-flat-button
        color="secondary">
        {{ 'GLOBAL_ADMIN.CREATE_USER.CANCEL' | translate }}
    </button>
    <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="changePassword(changePasswordForm)" type="submit" [disabled]="!changePasswordForm.valid">
        {{ 'PROFILE.CHANGE_PASSWORD_SUBMIT' | translate }}
    </button>
</form-footer>