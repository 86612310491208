/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/pageable';
import { Document } from 'src/models/document.model';
import { Page } from 'src/app/page';
import * as printJS from 'print-js';

@Injectable({
   providedIn: 'root',
})
export class AlfrescoService {
   constructor(private http: HttpClient, private appService: AppService) {}

   getDocumentsForSubject(pageable: Pageable, filterValue: string, subjectId: string, csrId: any): Observable<Page<Document>> {
      return this.http.post(this.appService.getUrl() + environment.documentsUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + subjectId + '/' + csrId, filterValue) as Observable<any>;
   }

   getDocumentsForCase(pageable: Pageable, filterValue: string, subjectId: string, caseId: string, csrId: any) {
      return this.http.post(
         this.appService.getUrl() + environment.documentsUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + subjectId + '/' + caseId + '/' + csrId,
         filterValue
      ) as Observable<any>;
   }

   postFileForSubject(fileToUpload: File, id: string, docType: Number) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.http.post(this.appService.getUrl() + environment.uploadUrl + id + '/' + docType, formData) as Observable<any>;
   }

   postFileForSubjectsCase(fileToUpload: File, subjectId: string, caseId: string, docType: Number) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);

      return this.http.post(this.appService.getUrl() + environment.uploadUrl + subjectId + '/' + caseId + '/' + docType, formData, {
         responseType: 'text',
      }) as Observable<any>;
   }

   viewDocument(folder: string, fileName: string) {
      return this.http.post(this.appService.getUrl() + environment.viewDocumentUrl + folder + '/' + fileName, {
         responseType: 'application/pdf',
      }) as Observable<any>;
   }

   downloadDocument(documentId: string, fileName: string, format) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', format);
      return this.http
         .get(this.appService.getUrl() + environment.downloadDocumentUrl + documentId + '/' + fileName, {
            headers,
            responseType: 'blob',
         })
         .subscribe(result => {
            const file = new Blob([result], { type: result.type });
            const fileURL = URL.createObjectURL(file);
            const anchor = document.createElement('a');
            const formats = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            const extension = fileName.split('.').pop();
            const hasValidExtension = fileName.includes('.') && formats.includes(format) && (extension === 'pdf' || extension === 'docx');
            if (!hasValidExtension) {
               if (format === 'application/pdf') {
                  fileName = fileName + '.pdf';
               } else if (format === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                  fileName = fileName + '.docx';
               }
            }
            anchor.download = fileName;
            anchor.href = fileURL;
            anchor.click();
         });
   }

   downloadDocumentByUrl(body: any) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.post(this.appService.getDocManagerUrl() + environment.documentDownloadTemplate, body, { headers, responseType: 'blob' }).subscribe(data => {
         const parts = [];
         parts.push('\uFEFF');
         parts.push(data);
         const fileURL = URL.createObjectURL(data);
         const a = document.createElement('a');
         document.body.appendChild(a);
         a.setAttribute('style', 'display: none');
         a.href = fileURL;
         a.download = body.fileName;
         a.click();
         window.URL.revokeObjectURL(fileURL);
         a.remove();
      });
   }
   getWebDavDocumentByUrl(body: any) {
      let headers = new HttpHeaders();
      this.http.post(this.appService.getDocManagerUrl() + environment.documentDownloadTemplateWebdav, body, {
          headers, responseType: 'blob'
      }).subscribe(data => {
          // Create a Blob URL for the received content
          const url = URL.createObjectURL(data);
          // Use the ms-word protocol to open the document
          const wordUrl = `ms-word:ofe|u|${url}`;
          window.open(wordUrl, '_blank');
      });
  }
   printDocument(documentId: string, fileName: string) {
      const headers = new HttpHeaders();
      this.http.get(this.appService.getUrl() + environment.downloadDocumentUrl + documentId + '/' + fileName, { responseType: 'blob', headers }).subscribe(data => {
         const file = new Blob([data], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         printJS(fileURL);
      });
   }

   getDocumentTemplates(caseKind: any) {
      return this.http.get(this.appService.getUrl() + environment.documentTemplatesUrl + caseKind) as Observable<any>;
   }

   deleteDocument(docId: any, subjectId: any) {
      return this.http.delete(this.appService.getUrl() + environment.deleteDocumentUrl + docId + '/' + subjectId) as Observable<any>;
   }

   getWorkerDocuments(workerId: any, pageable: Pageable, filterValue: any) {
      return this.http.post(this.appService.getUrl() + environment.workerDocumentsUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + workerId, filterValue) as Observable<any>;
   }

   handleSignature(handleSignature: any) {
      return this.http.post(this.appService.getUrl() + environment.handleSignatureUrl, handleSignature) as Observable<any>;
   }

   postFileForUserEvaluation(fileToUpload: File, workerId: any) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.http.post(this.appService.getUrl() + environment.uploadEvaluationUrl + '/' + workerId, formData) as Observable<any>;
   }

   findTargetedAssesment(caseId: any) {
      return this.http.get(this.appService.getUrl() + environment.findDirectedAssessment + caseId) as Observable<any>;
   }

   documentSendExpedition(sendingDocumentDto: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json;',
      });
      return this.http.post(this.appService.getUrl() + environment.sendingToExpedition, sendingDocumentDto) as Observable<any>;
   }

   checkIfFileExistIn(caseId: any, fileName: any) {
      return this.http.get(this.appService.getUrl() + environment.fileExistInCase + caseId + '/' + encodeURIComponent(fileName)) as Observable<any>;
   }
   getSubmissionDocument(submissionId: any, pageable: Pageable, filterValue: string): Observable<any> {
      return this.http.post(this.appService.getUrl() + environment.getSumissionDocuments + '/' + submissionId + '/' + pageable.pageNumber + '/' + pageable.pageSize, filterValue) as Observable<any>;
   }
}
