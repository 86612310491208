<container>
    <mat-card class="card">
        <card-header title="{{ 'STATISTICS.COLLECTION_STATS.TITLE' | translate }}">
        </card-header>
        <form [formGroup]="collectionStatsForm" class="form">
            <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline" (click)="selectMulti()">
                    <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
                    <mat-select formControlName="center" multiple required #multiSelect>
                        <input type="text" class="multi-input" autocomplete="off" matInput
                            placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch
                            (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false"
                            (input)="onInputChange($event.target.value)" />
                        <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                            {{ 'GLOBAL.ALL_CENTERS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id"
                            (click)="tosslePerOneCenter()">
                            {{ value.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="collectionStatsForm.get('center').hasError('required')">
                        {{ "GLOBAL.CENTER_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.COLLECTION_STATS.RECORD' | translate }}</mat-label>
                    <mat-select formControlName="record" required (selectionChange)="recordChange($event.value)">
                        <mat-option *ngFor="let record of recordOptions" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols"
                *ngIf="collectionStatsForm.controls.record.value!=null&&collectionStatsForm.controls.record.value!=''&&collectionStatsForm.controls.record.value.id!=1&&collectionStatsForm.controls.record.value.id!=2">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
                    <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
                        onlyNumber />
                    <mat-error *ngIf="collectionStatsForm.controls.selectedYear.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols"
                *ngIf="collectionStatsForm.controls.record.value!=null&&collectionStatsForm.controls.record.value!=''&&(collectionStatsForm.controls.record.value.id==1||collectionStatsForm.controls.record.value.id==2)">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.REPORTING_ON_DAY' | translate }}</mat-label>
                    <input matInput type="text" [matDatepicker]="selectedDay" formControlName="selectedDay" required
                        autocomplete="off" placeholder="{{ 'STATISTICS.REPORTING_ON_DAY' | translate }}" />
                    <mat-datepicker-toggle matSuffix [for]="selectedDay"></mat-datepicker-toggle>
                    <mat-datepicker #selectedDay></mat-datepicker>
                    <mat-error *ngIf="collectionStatsForm.controls.selectedDay.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button [disabled]="!collectionStatsForm.valid || btnDisabled"
                    class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="sendRequest(collectionStatsForm)">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>