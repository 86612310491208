/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/services/authentication.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
   loginForm: FormGroup;
   passwordFieldType: string = 'password';

   constructor(private formBuilder: FormBuilder, private authentication: AuthenticationService, private toastr: ToastrImplService) {}

   ngOnInit(): void {
      this.loginForm = this.formBuilder.group({
         username: ['', [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
         password: ['', [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
      });
   }

   login(submittedForm: FormGroup) {
      if (!this.checkBrowser()) {
         this.toastr.error('LOGIN.BROWSER_ERROR', null, 20000);
         return;
      }
      const username = submittedForm.get('username').value;
      const password = submittedForm.get('password').value;
      this.authentication.login(btoa(username), btoa(password));
      this.authentication.resetLoginTimer();
   }

   checkBrowser() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Chrome') || userAgent.includes('Edge')) {
         return true;
      }
      return false;
   }
   togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
   }
}
