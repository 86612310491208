/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData, durationInFacility } from 'src/app/utils/ageGroupData';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';
enum AutocompleteFilterEnum {
   PROBLEM = 'problem',
}
@Component({
   selector: 'general-record-stats',
   templateUrl: './general-record-stats.component.html',
   styleUrls: ['./general-record-stats.component.scss'],
})
export class GeneralRecordStatsComponent implements OnInit {
   generalRecordStatsForm: FormGroup;
   numberOfOptions = 11;
   recordOptions = [
      {
         id: 1,
         name: 'Укупан број корисника/деце у регистру ЦСР на активној евиденцији према старости и полу',
      },
      { id: 2, name: 'Кретање броја корисника у ЦСР у години' },
      {
         id: 3,
         name: 'Корисници на активној евиденцији ЦСР у години према пребивалишту корисника, полу и старости',
      },
      {
         id: 4,
         name: 'Број корисника на евиденцији ЦСР према радном статусу и старости на дан',
      },
      {
         id: 5,
         name: 'Број припадника ромске националности на евиденцији ЦСР према старости и полу',
      },
      {
         id: 6,
         name: 'Број корисника на евиденцији ЦСР према образовању и старости на дан',
      },
      {
         id: 7,
         name: 'Број корисника лишених пословне способности на дан',
      },
      {
         id: 8,
         name: 'Број деце према узрасту и врсти школе коју похађају на дан',
      },
      {
         id: 9,
         name: 'Број страних држављана/лица без држављанства у потреби за социјалном заштитом евидентираних у току године',
      },
      {
         id: 10,
         name: 'Број деце узраста до навршене 3. године на евиденцији, према дужини боравка у установи за смештај и полу',
      },
      {
         id: 11,
         name: 'Број корисника са проблемима у понашању на евиденцији у години према старости',
      },
   ];
   groups = {
      1: true,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
   };
   genderOptions = [];
   shouldShowAgeGroupRange = false;
   timeOptions = [
      { id: 1, name: 'За годину' },
      { id: 2, name: 'На дан' },
   ];
   ageGroupOne = ageGroupData;
   subjectStatusOptions = [
      { id: 1, name: 'Пренети' },
      { id: 2, name: 'Новоевидентирани' },
      { id: 3, name: 'Реактивирани' },
      { id: 4, name: 'Стављени у пасиву' },
   ];
   residenceOptions = [
      { id: 1, name: 'Градско' },
      { id: 2, name: 'Остало' },
   ];
   groupsWithDay = [4, 6, 7, 8, 10];
   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   maxDate: Date = new Date(2999, 11, 31);
   problemsCodebook = [];
   problemsCodebookFiltered: Observable<any[]>;

   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private statisticsService: StatisticsService,
      private localStorage: LocalStorageService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });

      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.codebookService.getGenderCodebook().subscribe(result => {
         this.genderOptions = result;
      });

      this.generalRecordStatsForm = this.formBuilder.group({
         selectedRecordOption: [this.recordOptions[0], []],
         center: [''],
         1: this.formBuilder.group({
            ageGroup: ['', []],
            gender: ['', []],
            selectedYear: ['', [Validators.required]],
            timeOption: [this.timeOptions[0]],
            dateDay: [null],
         }),
         2: this.formBuilder.group({
            ageGroup: ['', []],
            subjectStatus: ['', []],
            selectedYear: ['', []],
         }),
         3: this.formBuilder.group({
            ageGroup: ['', []],
            gender: ['', []],
            residence: ['', []],
            selectedYear: ['', []],
         }),
         4: this.formBuilder.group({
            ageGroup: ['', []],
            dateDay: [null],
         }),
         5: this.formBuilder.group({
            ageGroup: ['', []],
            gender: ['', []],
            selectedYear: ['', []],
         }),
         6: this.formBuilder.group({
            ageGroup: ['', []],
            dateDay: [null],
         }),
         7: this.formBuilder.group({
            ageGroup: ['', []],
            dateDay: [null],
         }),
         8: this.formBuilder.group({
            ageGroup: ['', []],
            dateDay: [null],
         }),
         9: this.formBuilder.group({
            ageGroup: ['', []],
            selectedYear: ['', []],
         }),
         10: this.formBuilder.group({
            ageGroup: ['', []],
            dateDay: [null],
         }),
         11: this.formBuilder.group({
            ageGroup: ['', []],
            selectedYear: ['', []],
            problem: ['', []],
         }),
         customAgeGroupTo: [''],
         customAgeGroupFrom: [''],
      });

      this.dynamicFormUpdate();
      this.dynamicAgeFormUpdate();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.generalRecordStatsForm);
   }
   dynamicFormUpdate() {
      this.generalRecordStatsForm.get('selectedRecordOption').valueChanges.subscribe(option => {
         const updatedGroups = {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
         };
         this.groups = {
            ...updatedGroups,
            [option.id]: true,
         };
         if (option.id === 10) {
            this.ageGroupOne = durationInFacility;
         } else if (option.id === 11) {
            let addAgeGroup = [
               { id: 20, name: 'Деца (0-13)', from: 0, to: 13 },
               { id: 21, name: 'Деца (14-15)', from: 14, to: 15 },
               { id: 22, name: 'Деца (16-17)', from: 16, to: 17 },
               { id: 23, name: 'Деца (18-20)', from: 18, to: 20 },
            ];
            this.ageGroupOne = [...addAgeGroup, ...ageGroupData];
            this.codebookService.getProblemsCodebook().subscribe(res => {
               this.problemsCodebook = res;
               this.problemsCodebook.sort((a, b) => a.title.localeCompare(b.title));
               this.problemsCodebookFiltered = (this.generalRecordStatsForm.get('11') as FormGroup).controls.problem.valueChanges.pipe(
                  startWith(''),
                  map(value => (typeof value === 'string' ? value : value?.title)),
                  map(title => (title ? this._filter(title, AutocompleteFilterEnum.PROBLEM) : this.problemsCodebook.slice()))
               );
            });
         } else {
            this.ageGroupOne = ageGroupData;
         }
         if (this.shouldShowAgeGroupRange === true) {
            this.generalRecordStatsForm.controls.customAgeGroupFrom.clearValidators();
            this.generalRecordStatsForm.controls.customAgeGroupFrom.setValue(null);
            this.generalRecordStatsForm.controls.customAgeGroupTo.clearValidators();
            this.generalRecordStatsForm.controls.customAgeGroupTo.setValue(null);

            this.shouldShowAgeGroupRange = false;
         }

         for (let index = 1; index <= this.numberOfOptions; index++) {
            (this.generalRecordStatsForm.get(index.toString()) as FormGroup).reset();
            if (index === 1) {
               (this.generalRecordStatsForm.get(index.toString()) as FormGroup).controls.timeOption.setValue(this.timeOptions[0]);
            }
            if (index === option.id && !this.groupsWithDay.includes(index)) {
               this.generalRecordStatsForm.get(index.toString())['controls'].selectedYear.setValidators(Validators.required);
            } else {
               if (index === 1 || this.groupsWithDay.includes(index)) {
                  this.generalRecordStatsForm.get(index.toString())['controls'].dateDay.clearValidators();
                  this.generalRecordStatsForm.get(index.toString())['controls'].dateDay.setErrors(null);
                  this.generalRecordStatsForm.get(index.toString())['controls'].dateDay.markAsUntouched();
               }
               if (!this.groupsWithDay.includes(index)) {
                  this.generalRecordStatsForm.get(index.toString())['controls'].selectedYear.clearValidators();
                  this.generalRecordStatsForm.get(index.toString())['controls'].selectedYear.setErrors(null);
                  this.generalRecordStatsForm.get(index.toString())['controls'].selectedYear.markAsUntouched();
               }
            }
            if (index === 11) {
               this.generalRecordStatsForm.get(index.toString())['controls'].problem.setValue(null);
               this.generalRecordStatsForm.get(index.toString())['controls'].problem.clearValidators();
               this.generalRecordStatsForm.get(index.toString())['controls'].problem.setErrors(null);
               this.generalRecordStatsForm.get(index.toString())['controls'].problem.markAsUntouched();
            }
         }
      });
   }
   changeTimePeriod(value: any) {
      if (value.id == 1) {
         this.generalRecordStatsForm.get('1')['controls'].dateDay.setValue('');
         this.generalRecordStatsForm.get('1')['controls'].dateDay.clearValidators();
         this.generalRecordStatsForm.get('1')['controls'].dateDay.setErrors(null);
         this.generalRecordStatsForm.get('1')['controls'].dateDay.markAsUntouched();
      } else {
         this.generalRecordStatsForm.get('1')['controls'].dateDay.setValue('');
         this.generalRecordStatsForm.get('1')['controls'].selectedYear.clearValidators();
         this.generalRecordStatsForm.get('1')['controls'].selectedYear.setErrors(null);
         this.generalRecordStatsForm.get('1')['controls'].selectedYear.markAsUntouched();
      }
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      if (!this.generalRecordStatsForm.valid) {
         return;
      }
      const queryId = form.value.selectedRecordOption.id;
      let ageGroupDto = null;
      let gender = null;
      let year = null;
      let day = null;
      let statusId = null;
      let residence = null;
      let durationInFacility = null;
      let problemId = null;

      if (queryId === 2) {
         statusId = form.value[2].subjectStatus === '' || form.value[2].subjectStatus === null ? null : form.value[2].subjectStatus;
      } else if (queryId === 3) {
         residence = form.value[3].residence === '' || form.value[3].residence === null ? null : form.value[3].residence;
      }
      year = form.value[queryId].selectedYear === '' || form.value[queryId].selectedYear === null ? null : form.value[queryId].selectedYear;
      if (queryId !== 2) {
         gender = form.value[queryId].gender === '' || form.value[queryId].gender === null ? null : form.value[queryId].gender;
      }
      if (queryId != 10) {
         ageGroupDto = form.value[queryId].ageGroup === null || form.value[queryId].ageGroup === '' ? null : form.value[queryId].ageGroup;
         if (ageGroupDto != null && ageGroupDto.some(e => e.id === 6)) {
            const indexToUpdate = ageGroupDto.findIndex(group => group.id === 6);
            const newAgeGroup = {
               id: 6,
               name: 'Слободан унос опсега година (' + form.value.customAgeGroupFrom + ' - ' + form.value.customAgeGroupTo + ')',
               from: form.value.customAgeGroupFrom,
               to: form.value.customAgeGroupTo,
            };
            ageGroupDto[indexToUpdate] = newAgeGroup;
         }
      } else {
         durationInFacility = form.value[queryId].ageGroup === null || form.value[queryId].ageGroup === '' ? null : form.value[queryId].ageGroup;
      }
      if ((queryId === 1 && form.value[queryId].timeOption.id === 2) || queryId === 4 || queryId === 6 || queryId === 7 || queryId === 8 || queryId === 10) {
         day = form.value[queryId].dateDay;
      }
      if (queryId == 11) {
         problemId = form.value[queryId].problem.id;
      }
      const dto = {
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center : [this.loggedIn.csrId],
         queryId,
         gender,
         year,
         day,
         statusId,
         residence,
         ...(queryId !== 10 ? { ageGroup: ageGroupDto } : { durationInFacility: durationInFacility }),
         problemId,
      };

      let filename = '';
      this.translate.get('STATISTICS.GENERAL_RECORD_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });

      this.statisticsService.getGenerallStats(dto, filename);
   }

   dynamicAgeFormUpdate() {
      for (let index = 1; index <= this.numberOfOptions; index++) {
         this.generalRecordStatsForm.get(index.toString())['controls'].ageGroup.valueChanges.subscribe(ageArray => {
            if (ageArray !== null && ageArray.some(age => age.id === 6)) {
               this.shouldShowAgeGroupRange = true;
               this.generalRecordStatsForm.controls.customAgeGroupFrom.setValidators(Validators.required);
               this.generalRecordStatsForm.controls.customAgeGroupTo.setValidators(Validators.required);
            } else {
               if (this.shouldShowAgeGroupRange === true) {
                  this.generalRecordStatsForm.controls.customAgeGroupFrom.clearValidators();
                  this.generalRecordStatsForm.controls.customAgeGroupFrom.setValue(null);
                  this.generalRecordStatsForm.controls.customAgeGroupTo.clearValidators();
                  this.generalRecordStatsForm.controls.customAgeGroupTo.setValue(null);
                  this.shouldShowAgeGroupRange = false;
               }
            }
         });
      }
   }
   checkCustomYears() {
      if (this.generalRecordStatsForm.controls.customAgeGroupTo.touched && this.generalRecordStatsForm.controls.customAgeGroupFrom.touched) {
         if (
            this.generalRecordStatsForm.value.customAgeGroupTo !== null &&
            this.generalRecordStatsForm.value.customAgeGroupTo !== '' &&
            this.generalRecordStatsForm.value.customAgeGroupFrom !== null &&
            this.generalRecordStatsForm.value.customAgeGroupFrom !== ''
         ) {
            if (Number(this.generalRecordStatsForm.value.customAgeGroupTo) < Number(this.generalRecordStatsForm.value.customAgeGroupFrom)) {
               this.generalRecordStatsForm.controls.customAgeGroupTo.setErrors({
                  incorect: true,
               });
            } else {
               this.generalRecordStatsForm.controls.customAgeGroupTo.setErrors(null);
            }
         }
      }
      if (this.generalRecordStatsForm.value.customAgeGroupTo !== null && this.generalRecordStatsForm.value.customAgeGroupTo !== '') {
         if (!this.generalRecordStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.generalRecordStatsForm.value.customAgeGroupTo) > 150) {
            this.generalRecordStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.generalRecordStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.generalRecordStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.generalRecordStatsForm.value.customAgeGroupFrom !== null && this.generalRecordStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.generalRecordStatsForm.value.customAgeGroupFrom) > 150) {
            this.generalRecordStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.generalRecordStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }

   compareObjectsId(object1: any, object2: any) {
      return object1 != null && object2 != null && object1.id === object2.id;
   }

   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.generalRecordStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.generalRecordStatsForm.controls.center.patchValue([]);
      }
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.generalRecordStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.generalRecordStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
   displayCustomType(objectValue: any) {
      return objectValue ? objectValue.title : '';
   }
   autoSelectType() {
      autoSelect(this.problemsCodebook, 'problem', 'title', this.generalRecordStatsForm.get('11') as FormGroup);
   }

   public _filter(value: string, type: string): any[] {
      const filterValue = value.toLowerCase();
      switch (type) {
         case AutocompleteFilterEnum.PROBLEM:
            return sortByFirstLetter(
               this.problemsCodebook.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'title'
            );
      }
   }
}
