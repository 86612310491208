/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { CaseService } from 'src/services/case.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { from, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SubjectsService } from 'src/services/subjects.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
   CASE_NUMBER = 'caseNum',
   TYPE_PROBLEM = 'typeProblem',
}
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */

@Component({
   selector: 'subject-new-problem',
   templateUrl: './subject-new-problem.component.html',
   styleUrls: ['./subject-new-problem.component.scss'],
})
export class SubjectNewProblemComponent implements OnInit {
   problem: any;
   problemForm: FormGroup;
   problemsIntensity: any = [];
   problemsDuration: any = [];
   problemsCodebook: any = [];
   subjectCases: any = [];
   minFromDate = new Date();
   minToDate = new Date();
   filteredCaseNumberOptions: Observable<any[]>;
   filteredProblemTypes: Observable<any[]>;
   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild('submitButton') submitButton;

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<SubjectNewProblemComponent>,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private caseService: CaseService,
      private subjectService: SubjectsService
   ) {
      this.getProblemsCodebook();
      this.getProblemsDurationCodebook();
      this.getProblemsIntensityCodebook();

      this.problemForm = this.formBuilder.group({
         problemType: ['', [Validators.required]],
         case: [''],
         subjectId: this.data.subjectId,
         id: null,
         fromDate: ['', [Validators.required]],
         toDate: [''],
         intensity: ['', [Validators.required]],
         duration: ['', [Validators.required]],
         csrId: [''],
      });

      this.subjectService.findCaseNumbersForSubject(this.data.subjectId).subscribe(result => {
         this.subjectCases = result;

         this.filteredCaseNumberOptions = this.problemForm.controls.case.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.caseNumber)),
            map(caseNumber => (caseNumber ? this._filter(caseNumber, AutocompleteFilterEnum.CASE_NUMBER) : this.subjectCases.slice()))
         );
      });

      if (this.data.problem !== null) {
         this.patchData();
      }
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   addNewProblems() {
      this.submitButton.disabled = true;
      this.checkCaseNum();
      if (!this.problemForm.valid) {
         return;
      }
      this.caseService.addCaseProblem(this.makeBody()).subscribe(
         res => {
            this.dialogRef.close({ event: 'Create', data: res });
         },
         () => {
            // error
            this.submitButton.disabled = false;
         }
      );
   }

   ngOnInit() {}

   displayCustomType(objectValue: any) {
      return objectValue ? objectValue.title : '';
   }

   getProblemsIntensityCodebook() {
      this.codebookService.getProblemsIntensityCodebook().subscribe(res => {
         this.problemsIntensity = res;
      });
   }

   getProblemsDurationCodebook() {
      this.codebookService.getProblemsDurationCodebook().subscribe(res => {
         this.problemsDuration = res;
      });
   }

   getProblemsCodebook() {
      this.codebookService.getActiveProblemsCodebook().subscribe(res => {
         this.problemsCodebook = res.sort((a: any, b: any) => a.title.localeCompare(b.title));

         this.filteredProblemTypes = this.problemForm.controls.problemType.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.TYPE_PROBLEM) : this.problemsCodebook.slice()))
         );
      });
   }

   autoSelectType() {
      autoSelect(this.problemsCodebook, 'problemType', 'title', this.problemForm);
   }

   public _filter(value: string, type: string): any[] {
      const filterValue = value.toLowerCase();
      switch (type) {
         case AutocompleteFilterEnum.CASE_NUMBER:
            return this.subjectCases.filter(option => option.caseNumber.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.TYPE_PROBLEM:
            return sortByFirstLetter(
               this.problemsCodebook.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'title'
            );
      }
   }

   displayCustomFormat(subjectCase: any): string {
      return subjectCase.caseNumber;
   }

   checkCaseNum() {
      if (this.problemForm.value.case !== '') {
         let found = false;
         for (const element of this.subjectCases) {
            if (element.id === this.problemForm.value.case.id) {
               found = true;
               this.problemForm.controls.case.setErrors(null);
               break;
            }
         }
         if (!found) {
            this.problemForm.controls.case.setErrors({ errorCaseNumber: true });
         }
      }
   }

   patchData() {
      const problem = this.data.problem;
      this.problemForm.patchValue({
         id: problem.problemId,
         csrId: problem.csrId,
         case:
            problem.caseId == null && problem.caseNumber == null
               ? ''
               : {
                    id: problem.caseId,
                    caseNumber: problem.caseNumber,
                 },
         fromDate: problem.fromDate,
         toDate: problem.toDate,
         intensity: problem.intensityId,
         duration: problem.durationId,
         problemType: { id: problem?.codebookId, code: problem?.code, title: problem?.codebookTitle },
      });
      if (problem?.codebookId) {
         this.problemForm.markAllAsTouched();
      }
   }

   compareObjects(object1: any, object2: any) {
      return object1 && object2 && object1 === object2;
   }

   dateValidator() {
      if (this.problemForm.controls.fromDate.value !== null && this.problemForm.controls.toDate.value !== null) {
         const d1 = new Date(this.problemForm.controls.fromDate.value);
         const d2 = new Date(this.problemForm.controls.toDate.value);
         const timeDifference = d2.getTime() - d1.getTime();
         const result = timeDifference / (1000 * 60 * 60 * 24);
         if (result < 0) {
            this.problemForm.get('toDate').setErrors({ errorToDate: true });
         }
      } else if (this.problemForm.controls.fromDate.value === null && this.problemForm.controls.toDate.value !== null) {
         this.problemForm.get('toDate').setErrors({ notValid: true });
      }
   }

   makeBody() {
      return {
         id: this.problemForm.value.id,
         caseId: this.problemForm.value.case.id,
         fromDate: this.problemForm.value.fromDate,
         toDate: this.problemForm.value.toDate,
         problemType: this.problemForm.value.problemType.id,
         subjectId: this.problemForm.value.subjectId,
         intensity: this.problemForm.value.intensity,
         duration: this.problemForm.value.duration,
         csrId: this.problemForm.value.csrId,
      };
   }
}
