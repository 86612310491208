import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { getNumberForShow, getNumberFromString, patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { AlfrescoService } from 'src/services/alfresco.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { PnnCaseService } from 'src/services/pnn-case-service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';

enum AutocompleteFilterEnum {
   PIODECISION = 'pioDecision',
   PIOFINDING = 'pioFinding',
}

@Component({
   selector: 'app-pnn',
   templateUrl: './pnn.component.html',
   styleUrls: ['./pnn.component.scss'],
})
export class PnnComponent implements OnInit {
   title: string;

   dateOfCaseCreation: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   isValid: any;
   caseData: any;
   subject: any;
   pnnForm: FormGroup;
   paymentMethodCodebook: any;
   initiatorCodebook: any;
   kind: any;
   subCaseKind: any;
   right: any;
   decisionTypes: [];
   isLawyer: any = false;
   enableConclusion = false;
   dateOfDecisionCreation: any;
   loadedData: any;
   pioDecisions: any = [];
   pioDecisionsFiltered: Observable<any[]>;
   loggedIn: any;
   pioDecisionRequred = false;
   fileToUpload: File = null;
   documentOptions: any;
   teamMember = false;
   pioFindingRequred = false;
   pioFindingsFiltered: Observable<any[]>;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   @ViewChild('submitButton') submitButton: any;
   isSubmited = false;
   noRegistrationId = false;
   terminationReasons: any = [];
   maxDate: Date = new Date(2999, 11, 31);

   public mask = {
      guide: false,
      showMask: false,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
   };

   decisionMakerOptions: any;
   hasProblem = false;

   constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog,
      private codebookService: CodebookService,
      private pnnCaseService: PnnCaseService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService,
      private alfrescoService: AlfrescoService,
      private route: ActivatedRoute,
      private userService: UserService,
      private expedition: ExpeditionService,
      private caseProblemRecordService: CaseProblemRecordService,
      private dataSharingService: DataSharingService
   ) {
      this.isLawyer = this.userService.isUserLawyer();
      this.getPioDesisions();
      this.route.params.subscribe(params => {
         this.subjectId = params.subjectId;
         this.caseId = params.id;
         const [_, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.isValid = this.subjectId !== undefined || this.subjectId !== null;
         if (this.isValid) {
            this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
            this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
            this.caseService.getSocialCase(this.caseId).subscribe(result => {
               this.caseData = result;
               this.subject = this.caseData.subject;
               if (this.subject.registrationId === null || this.subject.registrationId === '') {
                  this.noRegistrationId = true;
                  this.enableConclusion = false;
                  this.toastr.info('CASES.NO_REGISTER_NUMBER');
               }
               if (this.isLawyer) {
                  this.getDocumentsByTemplate();
               }
               if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                  this.teamMember = true;
               } else {
                  this.teamMember = false;
               }
               if (this.caseData.caseStatus === 'ACCEPTED') {
                  this.enableConclusion = true;
               } else {
                  this.enableConclusion = false;
               }
               this.getCaseKindViewCodebook(this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.kind, this.caseData.dateOfCreation);
               this.title = this.caseData.socialCaseClassificationCodebook.title;
               this.codebookService.getDecisionTypeCodebook().subscribe(result1 => {
                  this.decisionTypes = result1;
                  if (this.title.toLowerCase().includes('престанак')) {
                     this.decisionTypes.splice(0, 1);
                  }
               });
               this.caseHasProblem();
            });
            this.pnnCaseService.findPnnCaseById(this.caseId).subscribe(result => {
               this.loadedData = result;
               if (this.loadedData !== undefined && this.loadedData !== null) {
                  this.patchValues();
               }
            });
         }
      });

      this.pnnForm = this.formBuilder.group({
         dateOfCaseCreation: [''],
         dateOfDecisionCreation: new FormControl({ value: new Date(), disabled: true }),
         amount: ['', Validators.required],
         validFrom: ['', [Validators.required]],
         pioDecision: [''],
         pioNumDecision: new FormControl({ value: '', disabled: true }),
         pioDateDecision: new FormControl({ value: '', disabled: true }),
         decisionType: ['', [Validators.required]],
         paymentMethod: ['', [Validators.required]],
         initiator: [''],
         subjectId: [''],
         terminationOfRights: [''],
         refusalReason: [''],
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         account: [''],
         pioFinding: [''],
         pioNumFinding: new FormControl({ value: '', disabled: true }),
         pioDateFinding: new FormControl({ value: '', disabled: true }),
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         terminationReason: [''],
      });

      this.pnnForm.controls.pioDecision.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            this.pnnForm.controls.pioNumDecision.enable();
            if (this.pnnForm.controls.pioNumDecision.value !== '') {
               this.pnnForm.controls.pioDateDecision.enable();
            }
            this.pioDecisionRequred = true;
         } else {
            this.pnnForm.controls.pioNumDecision.setValue('');
            this.pnnForm.controls.pioNumDecision.disable();
            this.pioDecisionRequred = false;
         }
      });
      this.pnnForm.controls.pioFinding.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            this.pnnForm.controls.pioNumFinding.enable();
            if (this.pnnForm.controls.pioNumFinding.value !== '') {
               this.pnnForm.controls.pioDateFinding.enable();
            }
            this.pioFindingRequred = true;
         } else {
            this.pnnForm.controls.pioNumFinding.setValue('');
            this.pnnForm.controls.pioNumFinding.disable();
            this.pioFindingRequred = false;
         }
      });
      this.pnnForm.controls.pioNumFinding.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            if (this.pnnForm.controls.pioNumFinding.disabled) {
               this.pnnForm.controls.pioDateFinding.setValue('');
               this.pnnForm.controls.pioDateFinding.disable();
            } else {
               this.pnnForm.controls.pioDateFinding.enable();
            }
         } else {
            this.pnnForm.controls.pioDateFinding.setValue('');
            this.pnnForm.controls.pioDateFinding.disable();
         }
      });
      this.pnnForm.controls.pioNumDecision.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            if (this.pnnForm.controls.pioNumDecision.disabled) {
               this.pnnForm.controls.pioDateDecision.setValue('');
               this.pnnForm.controls.pioDateDecision.disable();
            } else {
               this.pnnForm.controls.pioDateDecision.enable();
            }
         } else {
            this.pnnForm.controls.pioDateDecision.setValue('');
            this.pnnForm.controls.pioDateDecision.disable();
         }
      });
   }

   ngOnInit(): void {
      this.getInitiatorCodebook();
      this.getPaymentMethodCodebook();
      this.getDecisionMakerCodebook();
   }

   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.enableConclusion = true;
      this.caseHasProblem();
      this.cdr.detectChanges();
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.enableConclusion = false;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
   }
   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(this.caseData, !this.pnnForm.valid, this.hasProblem);
   }
   checkAmount() {
      this.pnnForm.controls.amount.setValue(getNumberForShow(this.pnnForm.controls.amount.value));
   }

   async sendRequest() {
      this.isSubmited = true;
      const pnnCase = { ...this.createPnnObject(), isTempSave: false };
      const decisionTypeId = this.pnnForm.controls.decisionType.value.id;
      // ukoliko se doda jasper fajl potrebno je dodati kod za pravljenja
      // pdf iz jasper fajla i cuvanje podataka na BE i pozvati odavde metodu
      const addNewRecordRequered = await this.addNewRecord(decisionTypeId);
      if (addNewRecordRequered) {
         this.pnnCaseService.savePnnCase(pnnCase).subscribe(
            (pnnCaseId: number) => {
               if (pnnCaseId) {
                  this.addConclusionFinishCase();
               } else {
                  this.requestRejectedToastr(decisionTypeId);
                  this.enableSubmitButton();
               }
            },
            error => {
               manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               this.enableSubmitButton();
            }
         );
      } else {
         this.enableSubmitButton();
      }
   }

   private requestRejectedToastr(decisionTypeId: any) {
      if (decisionTypeId === 1) {
         this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_PNN');
      } else if (decisionTypeId === 3) {
         this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_PNN');
      }
   }
   private addConclusionFinishCase() {
      this.caseService.addConclusionFinishCase(this.pnnForm.get('lawyerFile').value, this.subjectId, this.caseData.id).subscribe(
         (documentId: string) => {
            this.toastr.success('SNACKBAR.SOLUTION_CREATED');
            this.localStorageService.set('case', true);
            if (documentId) {
               this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'pnn');
            }
            this.dataSharingService.cases.next(true);
         },
         error => {
            this.toastr.error('SNACKBAR.DECISION_CREATE_ERROR');
            this.enableSubmitButton();
         }
      );
   }

   saveCurrentData() {
      const pnnCase = { ...this.createPnnObject(), isTempSave: true };
      const decisionTypeId = this.pnnForm.controls.decisionType.value?.id;

      this.pnnCaseService.savePnnCase(pnnCase).subscribe(
         (pnnCaseId: number) => {
            if (pnnCaseId) {
               this.loadedData = { ...this.loadedData, id: pnnCaseId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.pnnForm.markAsPristine();
            } else {
               this.requestRejectedToastr(decisionTypeId);
            }
         },
         _ => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   getPaymentMethodCodebook() {
      this.codebookService.getPaymentMethodCodebook().subscribe(res => {
         this.paymentMethodCodebook = res;
      });
   }

   getInitiatorCodebook() {
      this.codebookService.getInitiatorCodebook().subscribe(res => {
         this.initiatorCodebook = res;
      });
   }

   getCaseKindViewCodebook(kind: any, dateOfCreation: any) {
      this.pnnForm.controls.dateOfCaseCreation.setValue(new Date(dateOfCreation));
      this.pnnForm.controls.dateOfCaseCreation.disable();
      this.codebookService.getCaseKindViewCodebookByKind(kind).subscribe(res => {
         this.subCaseKind = res;
      });
   }

   getDecisionMakerCodebook() {
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.loadedData?.socialCase?.decisionMaker) {
            this.pnnForm.controls.decisionMaker.setValue(result[0]);
            this.pnnForm.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.pnnForm.controls.decisionMaker.setValue(result[this.loadedData?.socialCase?.decisionMaker.id - 1]);
            this.pnnForm.controls.decisionMakerTitle.setValue(this.loadedData?.socialCase?.decisionMakerTitle);
         }
      });
   }
   createPnnObject() {
      const pnnCase = {
         id: this.loadedData ? this.loadedData.id : null,
         socialCaseId: this.caseData.id,
         dateOfDecisionCreation: new Date(),
         pioDecision: this.pnnForm.value.pioDecision === '' ? null : this.pnnForm.value.pioDecision,
         pioNumDecision: this.pnnForm.value.pioNumDecision,
         pioDateDecision: this.pnnForm.value.pioDateDecision,
         pioFinding: this.pnnForm.value.pioFinding !== '' ? this.pnnForm.value.pioFinding : null,
         pioNumFinding: this.pnnForm.value.pioNumFinding,
         pioDateFinding: this.pnnForm.value.pioDateFinding,
         decisionTypeCodebook: this.pnnForm.value.decisionType !== '' ? this.pnnForm.value.decisionType : null,
         initiatorCodebook: this.pnnForm.value.initiator !== '' ? this.pnnForm.value.initiator : null,
         paymentMethodCodebook: this.pnnForm.value.paymentMethod !== '' ? this.pnnForm.value.paymentMethod : null,
         validFrom: this.pnnForm.value.validFrom,
         amount: getNumberFromString(this.pnnForm.value.amount),
         terminationOfRights: this.pnnForm.value.terminationOfRights,
         refusalReason: this.pnnForm.value.refusalReason,
         accountNumber: this.pnnForm.value.account,
         decisionMaker: this.pnnForm.value.decisionMaker ? this.pnnForm.value.decisionMaker : null,
         decisionMakerDescription: this.pnnForm.value.decisionMaker?.id === 2 ? this.pnnForm.value.decisionMakerDescription : '',
         decisionMakerName: this.pnnForm.value.decisionMakerName ? this.pnnForm.value.decisionMakerName : '',
         decisionMakerTitle: this.pnnForm.value.decisionMakerTitle ? this.pnnForm.value.decisionMakerTitle : '',
         terminationReason: this.pnnForm.value.terminationReason !== '' ? this.pnnForm.value.terminationReason : null,
      };
      return pnnCase;
   }

   patchValues() {
      this.pnnForm = this.formBuilder.group({
         dateOfDecisionCreation: new FormControl({ value: new Date(), disabled: true }),
         dateOfCaseCreation: new FormControl({ value: new Date(this.loadedData.socialCase.dateOfCreation), disabled: true }),
         amount: this.loadedData.amount === null ? '' : patchForDecimalNumber(this.loadedData.amount),
         validFrom: this.loadedData.validFrom !== null ? new Date(this.loadedData.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.validFrom,
         pioDecision: this.loadedData.pioDecision,
         pioNumDecision: this.loadedData.pioNumDecision,
         pioDateDecision: this.loadedData.pioDateDecision !== null ? new Date(this.loadedData.pioDateDecision.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.pioDateDecision,
         decisionType: this.loadedData.decisionTypeCodebook,
         paymentMethod: this.loadedData.paymentMethodCodebook,
         initiator: this.loadedData.initiatorCodebook,
         subjectId: [''],
         terminationOfRights: this.loadedData.terminationOfRights,
         refusalReason: this.loadedData.refusalReason,
         account: this.loadedData.socialCase.accountNumber,
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         pioFinding: this.loadedData.pioFinding !== null ? this.loadedData.pioFinding : '',
         pioNumFinding: this.loadedData.pioNumFinding,
         pioDateFinding: this.loadedData.pioDateFinding !== null ? new Date(this.loadedData.pioDateFinding.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.pioDateFinding,
         decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[this.loadedData.socialCase.decisionMaker.id - 1],
         decisionMakerName: this.loadedData.socialCase.decisionMakerName,
         decisionMakerTitle: this.loadedData.socialCase.decisionMakerTitle,
         decisionMakerDescription: this.loadedData.socialCase.decisionMakerDescription,
         terminationReason: this.loadedData.terminationReason,
      });
      this.showOptionalFields();
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   getPioDesisions() {
      this.codebookService.getBranchesPioFundCodebook().subscribe(res => {
         this.pioDecisions = res;
         this.pioDecisionsFiltered = this.pnnForm.controls.pioDecision.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name, AutocompleteFilterEnum.PIODECISION) : this.pioDecisions.slice()))
         );
         this.pioFindingsFiltered = this.pnnForm.controls.pioFinding.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name, AutocompleteFilterEnum.PIOFINDING) : this.pioDecisions.slice()))
         );
      });
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.PIODECISION:
            return sortByFirstLetter(
               this.pioDecisions.filter((option: any) => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.PIOFINDING:
            return sortByFirstLetter(
               this.pioDecisions.filter((option: any) => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }

   CheckConclusions(checkPio: boolean) {
      if (checkPio) {
         return autoSelect(this.pioDecisions, 'pioDecision', 'name', this.pnnForm);
      }
      if (
         (this.pnnForm.controls.pioDecision.value.branchTypeCodebook === null || this.pnnForm.controls.pioDecision.value.branchTypeCodebook === undefined) &&
         this.pnnForm.controls.pioDecision.value !== ''
      ) {
         setTimeout(() => {
            this.pnnForm.controls.pioDecision.setErrors({ randomInput: true });
         });
      } else {
         setTimeout(() => {
            this.pnnForm.controls.pioDecision.setErrors(null);
         });
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.branchTypeCodebook.title + ' ' + objectValue.name : '';
   }

   showOptionalFields() {
      if (this.pnnForm.value.decisionType.id === 1) {
         this.pnnForm.controls.initiator.enable();
         this.pnnForm.controls.paymentMethod.enable();
         this.pnnForm.controls.validFrom.enable();
         this.pnnForm.controls.amount.enable();
         this.pnnForm.controls.account.enable();
         this.pnnForm.controls.account.setValidators(Validators.required);
      } else {
         this.pnnForm.controls.initiator.disable();
         this.pnnForm.controls.paymentMethod.disable();
         this.pnnForm.controls.amount.disable();
         this.pnnForm.controls.validFrom.disable();
         this.pnnForm.controls.initiator.setErrors(null);
         this.pnnForm.controls.paymentMethod.setErrors(null);
         this.pnnForm.controls.amount.setErrors(null);
         this.pnnForm.controls.initiator.setErrors(null);
         this.pnnForm.controls.account.setValue('');
         this.pnnForm.controls.account.clearValidators();
         this.pnnForm.controls.account.setErrors(null);
         this.pnnForm.controls.account.disable();
      }
      if (this.pnnForm.value.decisionType.id === 3) {
         this.pnnForm.controls.terminationReason.enable();
         this.pnnForm.controls.terminationReason.setValidators(Validators.required);
         this.pnnForm.controls.validFrom.enable();
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType('OPSTI').subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.pnnForm.controls.terminationReason.disable();
         this.pnnForm.controls.terminationReason.setErrors(null);
         this.pnnForm.controls.terminationReason.setValue(null);
         this.pnnForm.controls.terminationReason.clearValidators();
      }
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.pnnForm.get('lawyerFile').setValue(this.fileToUpload);
         this.pnnForm.get('fileName').setValue(this.fileToUpload.name);
      }

      this.file.nativeElement.value = null;
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const amountData = this.pnnForm.controls.amount.value !== '' && this.pnnForm.controls.amount.value !== undefined ? getNumberFromString(this.pnnForm.controls.amount.value) : null;
      const paymentTypeData =
         this.pnnForm.controls.paymentMethodCodebook.value !== '' && this.pnnForm.controls.paymentMethodCodebook.value !== undefined ? this.pnnForm.controls.paymentMethodCodebook.value.title : null;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
         amount: amountData,
         paymentType: paymentTypeData,
         accountNumber: this.pnnForm.value.account,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
         this.documentOptions = result;
      });
   }
   paymentMethodChanged() {
      if (this.pnnForm.value.paymentMethod === null || this.pnnForm.value.paymentMethod.id === 1 || this.pnnForm.value.paymentMethod.id === 6) {
         this.pnnForm.controls.account.setValue('');
         this.pnnForm.controls.account.clearValidators();
         this.pnnForm.controls.account.setErrors(null);
         this.pnnForm.controls.account.disable();
      } else {
         this.pnnForm.controls.account.enable();
         if (this.pnnForm.value.paymentMethod.id !== 2) {
            this.pnnForm.controls.account.setValidators(Validators.required);
         } else {
            this.pnnForm.controls.account.clearValidators();
            this.pnnForm.controls.account.setErrors(null);
         }
      }
   }
   CheckFindings(checkPio: boolean) {
      if (checkPio) {
         return autoSelect(this.pioDecisions, 'pioFinding', 'name', this.pnnForm);
      }
      if (
         (this.pnnForm.controls.pioFinding.value.branchTypeCodebook === null || this.pnnForm.controls.pioFinding.value.branchTypeCodebook === undefined) &&
         this.pnnForm.controls.pioFinding.value !== ''
      ) {
         setTimeout(() => {
            this.pnnForm.controls.pioFinding.setErrors({ randomInput: true });
         });
      } else {
         setTimeout(() => {
            this.pnnForm.controls.pioFinding.setErrors(null);
         });
      }
   }

   acceptedComplain() {
      this.caseData.newComplain = false;
   }
   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.pnnForm.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.pnnForm.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.pnnForm.controls.decisionMakerDescription.setValue('');
         this.pnnForm.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).subscribe(hasProblem => {
         this.hasProblem = hasProblem;
      });
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1 || decision === 3) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.pnnForm.controls.validFrom.value,
               amount: this.pnnForm.controls.amount.value,
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }

   private enableSubmitButton() {
      this.submitButton.disabled = false;
      this.isSubmited = false;
   }
   async saveChanges(): Promise<boolean> {
      if (this.isSubmited || this.pnnForm.pristine || this.caseData?.caseStatus === 'ASSIGNED') {
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef
         .afterClosed()
         .toPromise()
         .then(res => {
            if (res?.event !== 'cancel' && res !== undefined) {
               return this.saveCurrentData();
            }
         });
      return true;
   }
}
