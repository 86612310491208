<mat-dialog-content>
  <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
    [mat-dialog-close]="true"><span class="material-icons">close</span>
  </button>
  <div class="container">
    <form flex [formGroup]="problemForm" (ngSubmit)="addNewProblems()">
      <h2 *ngIf="!data.problem?.codebookId"> {{ 'NEW_SUBJECT_PROBLEM.TITLE' | translate }}</h2>
      <h2 *ngIf="data.problem?.codebookId"> {{ 'SUBJECT_PROBLEMS.TABLE.EDIT_PROBLEM' | translate }}</h2>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.CASE_ID' | translate }}</mat-label>
          <input type="text" matInput formControlName="case" (focusout)="checkCaseNum()"
            [matAutocomplete]="autoCaseNumber" [readonly]="data.fromCase" />
          <mat-autocomplete #autoCaseNumber="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCaseNumberOptions | async" [value]="option">
              {{ option.caseNumber }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="problemForm.get('case').hasError('errorCaseNumber')">
            {{ 'NEW_SUBJECT_PROBLEM.CASE_NUMBER_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="problemForm.get('case').hasError('required')">
            {{ 'NEW_SUBJECT_PROBLEM.CASE_ID_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="problemForm.get('case').hasError('pattern')">
            {{ 'NEW_SUBJECT_PROBLEM.CASE_NUMBER_ERROR_PATTERN' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_TYPE' | translate }}</mat-label>
          <input formControlName="problemType" required [readonly]="data.problem?.codebookId ? true : false" type="text"
            matInput [matAutocomplete]="autoType" (focusout)="autoSelectType()" />
          <mat-autocomplete #autoType="matAutocomplete" class="custom-autocomplete" [displayWith]="displayCustomType">
            <mat-option *ngFor="let problemCodebook of filteredProblemTypes | async" [value]="problemCodebook">
              {{ problemCodebook.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="problemForm.get('problemType').hasError('required')">
            {{ 'NEW_SUBJECT_PROBLEM.PROBLEM_TYPE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_INTENSITY' | translate }}</mat-label>
          <mat-select formControlName="intensity" [compareWith]="compareObjects" required>
            <mat-option *ngFor="let problemIntensity of problemsIntensity" [value]="problemIntensity.id">
              {{ problemIntensity.intensity }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="problemForm.get('intensity').hasError">
            {{ 'NEW_SUBJECT_PROBLEM.PROBLEM_INTENSITY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_DURATION' | translate }}</mat-label>
          <mat-select formControlName="duration" [compareWith]="compareObjects" required>
            <mat-option *ngFor="let problemDuration of problemsDuration" [value]="problemDuration.id">
              {{ problemDuration.duration }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="problemForm.get('duration').hasError('required')">
            {{ 'NEW_SUBJECT_PROBLEM.PROBLEM_DURATION' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_START' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickerStartDate" formControlName="fromDate" autocomplete="off" required
            (dateChange)="dateValidator()" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_END' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickerEndDate" formControlName="toDate" autocomplete="off"
            (dateChange)="dateValidator()" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
          <mat-error *ngIf="problemForm.get('toDate').hasError('errorToDate')">
            {{ 'NEW_SUBJECT_PROBLEM.DATE_ERROR' | translate }}</mat-error>
          <mat-error *ngIf="problemForm.get('toDate').hasError('notValid')">
            {{ 'NEW_SUBJECT_PROBLEM.DATE_ERROR_EMPTY' | translate }}</mat-error>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
          class="form-footer__button form-footer__button--secondary">
          {{ 'NEW_SUBJECT_PROBLEM.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
          [disabled]="!problemForm.valid" #submitButton>
          <span *ngIf="!data.problem?.codebookId">
            {{ 'NEW_SUBJECT_PROBLEM.SUBMIT' | translate }}
          </span>
          <span *ngIf="data.problem?.codebookId">
            {{ 'SUBJECT_PROBLEMS.TABLE.EDIT_PROBLEM' | translate }}
          </span>
        </button>
      </form-footer>
    </form>
    <br />
  </div>
</mat-dialog-content>