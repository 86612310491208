/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData } from 'src/app/utils/ageGroupData';
import { autoSelect } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'app-collection-stats',
   templateUrl: './collection-stats.component.html',
   styleUrls: ['./collection-stats.component.scss'],
})
export class CollectionStatsComponent implements OnInit {
   collectionStatsForm: FormGroup;
   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   ageGroupOne = ageGroupData;
   shouldShowAgeGroupRange = false;

   recordOptions = [
      { id: 1, name: 'Врста смештаја корисника под сталним старатељством, на евиденцији у старатељској књизи на дан, према решењу о старатељству и полу корисника' },
      { id: 2, name: 'Вршиоци дужности старатеља за кориснике под сталним старатељством (евидентиране у старатељској књизи) на дан према броју корисника' },
      {
         id: 3,
         name: 'Број решења о престанку старатељске заштите према евиденцији у старатељским књигама (стално старатељство) за пунолетне кориснике донетих у току године према разлозима престанка старатељске заштите',
      },
      { id: 4, name: 'Број сродничких и других хранитељских породица на евиденцији у години' },
      { id: 5, name: 'Број изречених мера заштите од насиља у породици (према Породичном закону) евидентираних у години према врсти мера' },
   ];

   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private localStorage: LocalStorageService,
      private statisticsService: StatisticsService,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
   }

   ngOnInit(): void {
      this.collectionStatsForm = this.formBuilder.group({
         record: ['', [Validators.required]],
         center: [''],
         selectedYear: ['', []],
         selectedDay: ['', []],
      });
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.collectionStatsForm);
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      let year = null;
      let day = null;
      if (form.value.record.id != 1 && form.value.record.id != 2) {
         year = form.value.selectedYear === '' || form.value.selectedYear === null ? null : form.value.selectedYear;
      } else {
         day = form.value.selectedDay === '' || form.value.selectedDay === null ? null : form.value.selectedDay;
      }
      const dto = {
         queryId: form.value.record.id,
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center : [this.loggedIn.csrId],
         year,
         day,
      };
      let filename = '';
      this.translate.get('STATISTICS.COLLECTION_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      this.statisticsService.getCollectionStatistics(dto, filename);
   }

   recordChange(record) {
      this.collectionStatsForm.controls.selectedYear.setValue('');
      this.collectionStatsForm.controls.selectedDay.setValue('');
      this.collectionStatsForm.controls.selectedYear.setErrors(null);
      this.collectionStatsForm.controls.selectedDay.setErrors(null);

      this.collectionStatsForm.markAsUntouched();
   }
   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.collectionStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.collectionStatsForm.controls.center.patchValue([]);
      }
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.collectionStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.collectionStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
}
