import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { environment } from 'src/environments/environment';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AssuranceService } from 'src/services/assurance-service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';

@Component({
   selector: 'app-assurance',
   templateUrl: './assurance.component.html',
   styleUrls: ['./assurance.component.scss'],
})
export class AssuranceComponent implements OnInit {

  assuranceForm: FormGroup;
  creationDate: any;
  caseName: any;
  caseId: any;
  subjectId: any;
  caseData: any;
  isValid: any;
  subject: any;
  title: string;
  loggedIn: any;
  teamMember = false;
  @ViewChild('submitButton') submitButton;
  isSubmited:boolean = false;
  documentOptions: any;
  fileToUpload: File = null;
  @ViewChild('file') file: ElementRef<HTMLInputElement>;
  caseOnlyUpload=false;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private caseService: CaseService,
    private cdr: ChangeDetectorRef,
    private subjectService: SubjectsService,
    private assuranceService: AssuranceService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private alfrescoService: AlfrescoService,
    private authentication: AuthenticationService,
    private route: ActivatedRoute,
    private toastr: ToastrImplService,
    private dataSharingService: DataSharingService,    
    public dialog: MatDialog,) {

    this.route.params.subscribe((params: any) => {
      this.caseId = params.id;
      this.subjectId = params.subjectId;
      this.isValid = this.subjectId !== undefined || this.subjectId !== null;
      if (this.isValid) {
        const [, caseType] = this.router.url.split('/cases/');
        if (caseType !== '') {
          this.caseName = caseType.split('/')[0];
        }
        this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
        this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
        this.caseService.getSocialCase(this.caseId).subscribe(
          (result) => {
            this.getUser();
            this.caseData = result;
            this.caseOnlyUpload=this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.id===28;
            this.getDocumentsByTemplate();
            if (this.loggedIn?.id !== this.caseData.assignedUserId) {
              this.teamMember = true;
            } else {
              this.teamMember = false;

               }
               this.title = this.caseData.socialCaseClassificationCodebook.title;
            });
         }
         this.assuranceForm = this.formBuilder.group({
            dateOfCreation: new FormControl({ value: new Date(), disabled: false }),
            purpose: ['', [Validators.required]],
            fileName: ['', []],
            uploadedFile: [''],
         });
      });
   }

   ngOnInit(): void {}

   getUser() {
      this.subjectService.getCurrentSubject(this.subjectId, this.loggedIn?.csrId).subscribe(res => {
         this.subject = res;
      });
   }

   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.cdr.detectChanges();
   }

   createAssuranceObject() {
      const assuranceObject = {
         socialCaseId: this.caseData.id,
         dateOfCreation: this.assuranceForm.value.dateOfCreation,
         purpose: this.assuranceForm.value.purpose,
      };
      return assuranceObject;
   }

   preview() {
      const assurance = this.createAssuranceObject();
      this.assuranceService.printPreview(assurance);
   }

   saveAssurance() {
      this.isSubmited = true;
      const fileToUpload = this.assuranceForm.get('uploadedFile').value;
      if (fileToUpload !== '') {
         this.sendWithFile(fileToUpload);
      } else {
         this.sendWithoutFile();
      }
   }

   sendWithFile(fileToUpload: any) {
      this.caseService.saveAssuranceCase(fileToUpload, this.subjectId, this.caseId).subscribe(
         result => {
            this.isSubmited = false;
            this.toastr.success('SNACKBAR.CERTIFICATE_GENERETED');
            this.dataSharingService.cases.next(true);
            this.router.navigateByUrl('/subjects/' + this.subjectId);
         },
         error => {
            this.isSubmited = false;
            this.toastr.error('SNACKBAR.CERTIFICATE_GENERETE_ERROR');
         }
      );
   }

   sendWithoutFile() {
      const assurance = this.createAssuranceObject();
      this.assuranceService.saveAssurance(assurance).subscribe(
         result => {
            this.isSubmited = false;
            this.toastr.success('SNACKBAR.CERTIFICATE_GENERETED');
            this.localStorageService.set('case', true);
            this.dataSharingService.cases.next(true);
            this.router.navigateByUrl('/subjects/' + this.subjectId);
         },
         error => {
            this.submitButton.disabled = false;
            this.isSubmited = false;
            this.toastr.error('SNACKBAR.CERTIFICATE_GENERETE_ERROR');
         }
      );
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseName).subscribe(result => {
         this.documentOptions = result;
      });
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
        documentId: doc.documentId,
        fileName: doc.name,
        caseId: this.caseId === undefined ? null : this.caseId,
     };
     this.alfrescoService.downloadDocumentByUrl(body);
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);

      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.assuranceForm.get('uploadedFile').setValue(this.fileToUpload);
         this.assuranceForm.get('fileName').setValue(this.fileToUpload.name);
      }
   }

   removeFile() {
      this.assuranceForm.get('uploadedFile').setValue('');
      this.assuranceForm.get('fileName').setValue('');
      this.fileToUpload = null;
      this.file.nativeElement.value = null;
   }
   acceptedComplain() {
      this.caseData.newComplain = false;
   }
}

