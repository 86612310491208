/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SendingDocumentsComponent } from 'src/app/components/sending-documents/sending-documents.component';
import { LocalStorageService } from 'src/app/local-storage.service';
import { Page } from 'src/app/page';
import { filterByDateUtil, removeFilterByDateUtil, resetFilterByDateUtil } from 'src/app/utils/filterListByDate';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { environment } from 'src/environments/environment';
import { Document } from 'src/models/document.model';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { SubjectsService } from 'src/services/subjects.service';
import { SubmissionService } from 'src/services/submission.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { SubjectDocumentsDialogComponent } from './subject-documents-dialog/subject-documents-dialog.component';
import { AppService } from 'src/services/app.service';

interface DocumentListItem {
   documentId: string;
   name: string;
}
enum AutocompleteFilterEnum {
   TEMPLATE = 'template',
}
@Component({
   // tslint:disable-next-line: component-selector
   selector: 'subject-documents',
   templateUrl: './subject-documents.component.html',
   styleUrls: ['./subject-documents.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class SubjectDocumentsComponent implements OnInit {
   csrIdFromTab: any;
   @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   @Input() addBtnDisabled;
   filterForm: FormGroup;
   filterValue: {
      minDate: any;
      maxDate: any;
      text: any;
   };
   fillteredDocumentListMock: any[] = [];
   formattedListInitial: any[] = [];
   appliedFilters: {
      displayValue: string;
      value: object | string;
      key: 'minDate' | 'maxDate';
   }[] = [];
   badgeCount: number;
   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };

   /**
    * Ukoliko se nalazimo u predmetu treba da ima mogucnost za odabir, sablona, u suprotnom ne (npr.kod Dosijea)
    */
   createDocumentOption = true;

   documentOptions: DocumentListItem[] = [];
   filteredDocumentOptions: Observable<any[]>;

   isUserWorker = false;
   documents = [];
   fileToUpload: File = null;
   subjectId: string;
   caseId: any;
   caseName: any;
   page: Page<Document> = new Page();
   isLoading = true;
   loggedIn: any;
   isMainCenterBelgrade = false;
   addButtonDisabled = true;
   isUserDrugostepeni = false;
   isUserGlobalAdmin = false;
   templatesForm: FormGroup;
   localStorageCenterId: any;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private submissionService: SubmissionService,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private router: Router,
      public dialog: MatDialog,
      private alfrescoService: AlfrescoService,
      private cdr: ChangeDetectorRef,
      private paginationService: CustomPaginationService,
      private datepipe: DatePipe,
      private authentication: AuthenticationService,
      private alfresco: AlfrescoService,
      private dataSharingService: DataSharingService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private caseService: CaseService,
      private subjectService: SubjectsService,
      private toastr: ToastrImplService,
      private userService: UserService,
      private appService: AppService
   ) {
      this.dataSharingService.acceptedSubmission.subscribe(() => {
         this.processSubmissionUrl(this.router.url);
      });

      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.userService.isUserMainBelgrade();
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      this.isUserGlobalAdmin = this.userService.isGlobalAdmin();
      this.route.params.subscribe(params => {
         this.csrIdFromTab = params.csrId;
      });

      if (this.isUserDrugostepeni) {
         this.createDocumentOption = false;
      }

      if (this.filterValue === undefined) {
         this.filterValue = {
            minDate: '',
            maxDate: '',
            text: [],
         };
      }
      this.dataSharingService.subjectStatusChange.subscribe(result => {
         if (result !== null) {
            this.addButtonDisabled = result;
         }
      });

      this.dataSharingService.caseStatusChange.subscribe(result => {
         if (result !== null) {
            this.addButtonDisabled = result;
         }
      });
   }

   ngOnInit(): void {
      this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
      this.getAllDocuments();
      this.templatesForm = this.formBuilder.group({ templateName: [null] });
      this.searchTemplatesInitialize();

      this.filterForm = this.formBuilder.group({
         minDate: ['', []],
         maxDate: ['', []],
      });

      // ovo se poziva prilikom inicializacije tako da se funkcija getDocuments poziva 2 puta potrebno proveriti
      // da li bi se moglo uspeti bez da se poziv funkcije iz reda 168 obrise @Andrija Trifunocic
   }

   getAllDocuments() {
      this.isLoading = true;
      const url = this.router.url;
      this.documentOptions = [];
      if (url.includes('/cases/')) {
         this.createDocumentOption = true;
         const [, caseType] = url.split('/cases/');
         if (caseType !== '') {
            [this.caseName, this.caseId, this.subjectId] = caseType.split('/');
            if (this.caseName === 'PRIJEMNI_LIST') {
               this.getDocumentsForSubject(this.filterValue);
            } else if (this.caseName === 'details') {
               this.createDocumentOption = false;
               this.getDocumentsForCase(this.filterValue);
            } else {
               this.isUserWorker = this.userService.isUserWorker();
               if (this.caseName !== 'PMN' && this.caseName !== 'UV') {
                  this.caseService.getSocialCase(this.caseId).subscribe((res: any) => {
                     this.alfrescoService.getDocumentTemplates(res.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
                        this.documentOptions = this.documentOptions.concat(result);
                        this.searchTemplatesInitialize();
                     });
                  });
               } else {
                  this.alfrescoService.getDocumentTemplates(this.caseName).subscribe(result => {
                     this.documentOptions = this.documentOptions.concat(result);
                     this.searchTemplatesInitialize();
                  });
               }
               // Dodavanje opstih template dokumenata
               this.alfrescoService.getDocumentTemplates('OPSTI').subscribe(result => {
                  // pored namjeskih dokumenata za specificne postupke, dobavljaju se i opsti dokumenti.
                  this.documentOptions = this.documentOptions.concat(result);
                  this.searchTemplatesInitialize();
                  this.cdr.detectChanges();
               });
               this.getDocumentsForCase(this.filterValue);
            }
         }
      } else if (url.includes('/subjects/')) {
         this.createDocumentOption = false;

         const [, id] = url.split('/subjects/');
         this.subjectId = id;
         this.getDocumentsForSubject(this.filterValue);
         this.caseId = undefined;
         this.caseName = null;
      } else if (url.includes('/submissions/entrancePaper/')) {
         this.processSubmissionUrl(url);
      }
   }

   getData(filter: { minDate: any; maxDate: any; text: any }) {
      if (this.page.numberOfElements === 1 && this.page.pageable.pageNumber !== 0) {
         this.page.pageable.pageNumber = this.page.pageable.pageNumber - 1;
      }
      if (this.caseId === undefined) {
         this.getDocumentsForSubject(filter);
      } else {
         this.getDocumentsForCase(filter);
      }
   }

   public getNextPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getData(this.filterValue);
   }

   public getPreviousPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getData(this.filterValue);
   }
   public getFirstPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getData(this.filterValue);
   }

   public getLastPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getData(this.filterValue);
   }
   public getPageInNewSize(pageSize: any): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.getData(this.filterValue);
   }

   getDocumentsForSubject(filter: any) {
      let x = null;
      let y = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni || this.isUserGlobalAdmin) {
         this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
         x = this.alfrescoService.getDocumentsForSubject(this.page.pageable, filter, this.subjectId, this.localStorageCenterId);
         y = this.subjectService.getSubjectStatus(this.subjectId, this.localStorageCenterId);
      } else {
         x = this.alfrescoService.getDocumentsForSubject(this.page.pageable, filter, this.subjectId, this.loggedIn?.csrId);
         y = this.subjectService.getSubjectStatus(this.subjectId, this.loggedIn?.csrId);
      }
      x.subscribe(
         (page: any) => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.isLoading = false;
            if (!this.cdr['destroyed']) {
               this.cdr.detectChanges();
            }
         },
         (error: any) => {
            console.log(error);
         }
      );

      y.subscribe((result: any) => {
         if (result.id === 1) {
            this.addButtonDisabled = false;
         } else {
            this.addButtonDisabled = true;
         }
      });
   }

   getDocumentsForCase(filter: any) {
      let x = null;
      if (this.addBtnDisabled) {
         this.addButtonDisabled = this.addBtnDisabled;
      } else {
         this.caseService.getSocialCaseDetails(this.caseId).subscribe(result => {
            if (result.caseStatus === 'ACCEPTED' && (result.assignedUserId === this.loggedIn.id || result.teamMembers.includes(this.loggedIn.id))) {
               this.addButtonDisabled = false;
            } else {
               this.addButtonDisabled = true;
            }
         });
      }

      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         if (this.localStorageCenterId === 0 || this.localStorageCenterId === undefined) {
            if (this.csrIdFromTab !== undefined) {
               x = this.alfrescoService.getDocumentsForCase(this.page.pageable, filter, this.subjectId, this.caseId, this.csrIdFromTab);
            }
         } else {
            x = this.alfrescoService.getDocumentsForCase(this.page.pageable, filter, this.subjectId, this.caseId, this.localStorageCenterId);
         }
      } else {
         x = this.alfrescoService.getDocumentsForCase(
            this.page.pageable,
            filter,
            this.subjectId,
            this.caseId,
            this.loggedIn?.csrId ? this.loggedIn?.csrId : this.csrIdFromTab ? this.csrIdFromTab : this.localStorageCenterId
         );
      }
      if (x !== null) {
         x.subscribe(
            (page: any) => {
               this.page = page;
               this.page.pageable.pagePerShow = page.number + 1;
               this.isLoading = false;
               if (!this.cdr['destroyed']) {
                  this.cdr.detectChanges();
               }
            },
            (error: any) => {
               console.log(error);
            }
         );
      }
   }

   // Filters (apply, remove, reset)
   applyFilter() {
      this.isLoading = true;
      const { appliedFilters, badgeCount } = filterByDateUtil({
         filterForm: this.filterForm,
         list: [],
         trigger: this.trigger,
      });

      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;

      if (this.filterForm.get('minDate').value !== '') {
         // this.filterValue.minDate = this.datepipe.transform(new Date(this.filterForm.get('minDate').value), 'yyyy-MM-dd');
         this.filterValue.minDate = this.datepipe.transform(new Date(this.filterForm.get('minDate').value), 'yyyy-MM-dd');
      }
      if (this.filterForm.get('maxDate').value !== '') {
         this.filterValue.maxDate = this.datepipe.transform(new Date(this.filterForm.get('maxDate').value), 'yyyy-MM-dd');
      }

      this.getData(this.filterValue);
   }

   removeFilter(filter: { displayValue: string; value: object | string; key: 'minDate' | 'maxDate' }): void {
      this.isLoading = true;
      const { appliedFilters, badgeCount } = removeFilterByDateUtil({
         filter,
         appliedFilters: this.appliedFilters,
         filterForm: this.filterForm,
         list: [],
      });

      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;
      if (filter.key === 'minDate') {
         this.filterValue.minDate = '';
      }
      if (filter.key === 'maxDate') {
         this.filterValue.maxDate = '';
      }

      this.getData(this.filterValue);
   }

   resetFilter() {
      const { appliedFilters, badgeCount } = resetFilterByDateUtil({ trigger: this.trigger, list: [], filterForm: this.filterForm });
      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;
      this.filterValue.minDate = '';
      this.filterValue.maxDate = '';
      this.getData(this.filterValue);
   }

   handleFileInput(files: FileList) {
      if (this.caseId !== undefined && this.caseName !== 'PRIJEMNI_LIST') {
         this.fileToUpload = files.item(0);
         this.alfresco.checkIfFileExistIn(this.caseId, this.fileToUpload.name).subscribe((res: any) => {
            if (res === true) {
               this.uploadDocument(files);
            } else {
               this.object.document = this.fileToUpload;
               this.object.action = ModalEventEnum.CONFIRM;
               this.object.subjectId = this.subjectId;
               this.translate.get('WARNING_DIALOG.DOCUMENT_EXIST.MESSAGE').subscribe((resp: string) => {
                  this.object.message = resp + this.object.document.name + '?';
               });
               this.translate.get('WARNING_DIALOG.DOCUMENT_EXIST.TITLE').subscribe((resp: string) => {
                  this.object.title = resp;
               });
               const dialogRef = this.dialog.open(WarningMessageComponent, {
                  panelClass: 'overlay-panel',
                  disableClose: true,
                  width: ModalSizeEnum.MINI,
                  data: this.object,
               });
               dialogRef.afterClosed().subscribe(result => {
                  const { event, data } = result;
                  if (event === ModalEventEnum.CONFIRM && data.status) {
                     this.uploadDocument(files);
                  } else {
                     this.file.nativeElement.value = null;
                  }
               });
            }
         });
      } else {
         this.uploadDocument(files);
      }
   }

   uploadDocument(files: FileList) {
      const dialogRef = this.dialog.open(SubjectDocumentsDialogComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
      });

      dialogRef.afterClosed().subscribe(({ event, ...rest }) => {
         if (event === 'new') {
            this.fileToUpload = files.item(0);
            this.isLoading = true;
            if (this.caseId === undefined) {
               this.alfrescoService.postFileForSubject(this.fileToUpload, this.subjectId, rest.data.value).subscribe(
                  () => {
                     this.getDocumentsForSubject(this.filterValue);
                     this.isLoading = false;
                     this.cdr.detectChanges();
                  },
                  error => {
                     console.log(error);
                  }
               );
            } else {
               if (this.caseName === 'PRIJEMNI_LIST') {
                  this.alfrescoService.postFileForSubject(this.fileToUpload, this.subjectId, rest.data.value).subscribe(
                     () => {
                        this.getDocumentsForSubject(this.filterValue);
                        this.isLoading = false;
                        this.cdr.detectChanges();
                     },
                     error => {
                        console.log(error);
                     }
                  );
               } else {
                  this.alfrescoService.postFileForSubjectsCase(this.fileToUpload, this.subjectId, this.caseId, rest.data.value).subscribe(
                     () => {
                        this.getDocumentsForCase(this.filterValue);
                        this.isLoading = false;
                        this.cdr.detectChanges();
                     },
                     error => {
                        console.log(error);
                     }
                  );
               }
            }
            this.file.nativeElement.value = null;
         } else {
            this.file.nativeElement.value = null;
         }
      });
   }

   openDialog(action: 'delete' | 'view' | 'cancel' | 'send' | 'download' | 'print' | 'viewInNewTab', obj: any) {
      this.object.document = obj;
      this.object.action = action;
      this.object.subjectId = this.subjectId;
      if (action === ModalEventEnum.VIEW) {
         const dialogRef = this.dialog.open(PreviewDocumentComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            data: this.object,
         });

         dialogRef.afterClosed().subscribe(() => {});
      } else if (action === ModalEventEnum.SEND) {
         const dialogRef = this.dialog.open(SendingDocumentsComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            width: ModalSizeEnum.EXTRA_LARGE,
            data: this.object,
         });

         dialogRef.afterClosed().subscribe(result => {
            const { event } = result;
            if (event === 'Create') {
               this.toastr.success('SNACKBAR.DOCUMENT_SENT');
               this.isLoading = true;
               this.getAllDocuments();
            } else if (event === 'Unsuccessful') {
               this.toastr.error('SNACKBAR.DOCUMENT_SEND_ERROR');
            }
         });
      } else if (action === ModalEventEnum.DOWNLOAD) {
         this.alfresco.downloadDocument(obj.documentId, obj.name, obj.format);
      } else if (action === ModalEventEnum.PRINT) {
         this.alfresco.printDocument(obj.documentId, obj.name);
      } else if (action === ModalEventEnum.VIEW_IN_NEW_TAB) {
         const docUrl = this.appService.getUrl() + environment.viewDocumentUrl + this.object.document.documentId + '/' + this.object.document.name;
         window.open(docUrl, '_blank');
      }
   }

   generateTemplate(option: DocumentListItem) {
      const body = {
         documentId: option.documentId,
         fileName: option.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfrescoService.getWebDavDocumentByUrl(body);
   }

   /*
     Olja Andjelovski olja.andjelovski@iten.rs
     Vraća dokumente koji su pristigli uz podnesak (submission)
   */
   getDocumentsForSubmission(filter: any, submission: any) {
      if (submission != null) {
         const x = this.alfrescoService.getSubmissionDocument(submission.submission.id, this.page.pageable, filter);

         x.subscribe(
            page => {
               this.page = page;
               this.page.pageable.pagePerShow = page.number + 1;
               this.isLoading = false;
               this.cdr.detectChanges();
            },
            () => {}
         );
      }
      this.addButtonDisabled = true;
   }

   /*
     Olja Andjelovski olja.andjelovski@iten.rs
     Obrađuje url i poziva funkciju za vraćanje dokumenata
   */
   processSubmissionUrl(url: any) {
      if (url.includes('/submissions/entrancePaper/')) {
         const [, submissionId] = url.split('/submissions/entrancePaper/');
         this.submissionService.findById(submissionId).subscribe(res => {
            this.getDocumentsForSubmission(this.filterValue, res);
         });
      }
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.TEMPLATE:
            return this.documentOptions.filter(option => option.name.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue === null ? '' : objectValue.name;
   }

   searchTemplatesInitialize() {
      this.filteredDocumentOptions = this.templatesForm.controls.templateName.valueChanges.pipe(
         startWith(''),
         map(value => (typeof value === 'string' ? value : value.name)),
         map(name => (name ? this._filter(name, AutocompleteFilterEnum.TEMPLATE) : this.documentOptions.slice()))
      );
   }

   clear() {
      this.templatesForm.controls.templateName.setValue('');
   }
}
