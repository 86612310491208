<container>
  <page-intro-header title="{{ 'STATISTICS.TITLE' | translate }}">
  </page-intro-header>
  <mat-tab-group animationDuration="0">
    <mat-tab label="{{ 'STATISTICS.TAB_ONE' | translate }}">
      <ng-template matTabContent>
        <office-stats #officeStats></office-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_TWO' | translate }}">
      <ng-template matTabContent>
        <basic-stats #basicStats></basic-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_THREE' | translate }}">
      <ng-template matTabContent>
        <document-stats #documentStats></document-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_FOUR' | translate }}">
      <ng-template matTabContent>
        <nsp-stats #nspStats></nsp-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_FIVE' | translate }}">
      <ng-template matTabContent>
        <record-control-stats #recordStats></record-control-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_SIX' | translate }}">
      <ng-template matTabContent>
        <general-record-stats #generalStats></general-record-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_SEVEN' | translate }}">
      <ng-template matTabContent>
        <activity-stats #activityStats></activity-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_EIGHT' | translate }}">
      <ng-template matTabContent>
        <app-jnp-stats #jnpStats></app-jnp-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_NINE' | translate }}">
      <ng-template matTabContent>
        <accounting-procedures></accounting-procedures>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_TEN' | translate }}">
      <ng-template matTabContent>
        <app-decision-stats></app-decision-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_ELEVEN' | translate }}">
      <ng-template matTabContent>
        <app-termination-reason-stats></app-termination-reason-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_TWELVE' | translate }}">
      <ng-template matTabContent>
        <app-tracking-list-stats></app-tracking-list-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_THIRTEEN' | translate }}">
      <ng-template matTabContent>
        <app-violence-stats></app-violence-stats>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'STATISTICS.TAB_FOURTEEN' | translate }}">
      <ng-template matTabContent>
        <app-collection-stats></app-collection-stats>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</container>